/* eslint-disable camelcase */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './BookingConfirmed.scss';
import { BaseLayout } from '../../components/BaseLayout';

import ddLogo from '../../direct-debit-logo.svg';

import { SuccessIcon } from '../../components/Header/SuccessIcon/SuccessIcon';

import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';
import { Session, SearchSelectionRoom} from '../../hooks/types';

import { sessionGetSearchParams } from '../../services/search';
import { adultCountFromRooms, childCountFromRooms, roomsFromPaxString } from '../../services/pax';

import DynamicBookingConfirmed from './DynamicBookingConfirmed';
import PackageBookingConfirmed from './PakBookingConfirmed';

import {
  getBookingDetails,
  getBookingPayMade,
  bookingMailOut,
  journeyAdd,
  sessionRemove,
  bookingUpdateType,
  sessionSetBooked
} from '../../hooks/useRequest';
import { getCookie } from '../../services/session';

const BookingConfirmed: FunctionComponent = () => {

  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const [searchParams] = useSearchParams();
  const [bookingRequested, setBookingRequest] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(true);

  const [session, setSession] = useState<Session>();

  const [bookingPayMade, setPayMade] = useState<any>(null);

  const [selectedRooms, setSelectedRooms] = useState<SearchSelectionRoom[]>([]);


  useEffect(() => {

    sessionStorage.removeItem('session_cr');
    async function ini() {

      if (!bookingRequested) {
     
        const sessionId = searchParams.get('session') || '';
        const bookingId = searchParams.get('bid');
        const nBId = Number(bookingId);

        const sessionData = await getBookingDetails(userToken, sessionId, nBId)

        const sd = sessionData.session_from_booking.result;

        if(sessionData.session_from_booking.result == null){
          return;
        }
        const sels = sd.selections;
        const selectedRooms = roomsFromPaxString(sels.rooms) as SearchSelectionRoom[];
        setSelectedRooms(selectedRooms);

        const adults = adultCountFromRooms(selectedRooms);
        const children = childCountFromRooms(selectedRooms);
        const aid = sessionStorage.getItem('selectedAccommodationID');

        const jse = sessionStorage.getItem('js5');
        if(jse == null){
          journeyAdd(Number(aid), adults, children, selectedRooms.length, '', 'Confirmation', 5, session?.session || '', sels.trip_type, userToken)
        }
        sessionStorage.setItem('js5', '1');
        
          setSession(sessionData.session_from_booking.result);

          const bpm = await getBookingPayMade(userToken, sessionId, nBId);
          if(bpm == null){
            return;
          }
          if(bpm.booking_pay_made.result == 1){
            setPayMade(true);
          }else{
            setPayMade(false);
          }

        
  
          bookingUpdateType(userToken, Number(bookingId), sels.trip_type || 0, sessionId || '')
         
          sessionSetBooked(userToken ,sessionId).then(() => {
            sessionRemove(userToken, sessionId);
          })

    
          if(configuration.booking_conf.affs != null){
            for(const a of configuration.booking_conf.affs)
            {
              if(a.id == 1){

                const pors = sessionStorage.getItem('pors');

                if(pors != null){
                  continue
                }

                const hwtPorCookie = getCookie('hwt_por', false);

                
                //1 = paid on results
                let afffAmnt = 0;
                if(sd.basket.booking?.confirmed && bpm.booking_pay_made.result == 1 && hwtPorCookie != null)
                {
                  afffAmnt = sd.basket.price;
                }
                const porClientId = a.client_id;
                const elementTags = document.getElementById('headSection') as any;
  
                const tag = document.createElement('script');
                tag.id = 'por' + porClientId;
                tag.src = `https://portgk.com/create-sale?client=java&MerchantID=${porClientId}&SaleID=${nBId}&OrderValue=${afffAmnt}&ExcludeVAT=NO`
                elementTags.append(tag);
                sessionStorage.setItem('pors', '1');
              }
            }
          }
        
        
        setBookingRequest(true);
      }

    }

    ini();

  }, []);

  if (session?.basket.booking == null || bookingPayMade == null) {
    // Display a loader ?
    return <div>Loading ....</div>;
  }



  let bookMessage = null;
  let payPlan = null;
  let ddSection = null;
  let p = [];
  for(const x of configuration.booking_conf.success){
    p.push(<>{x}<br></br><br></br></>)
  }
  bookMessage =  <p className='booking-text'>{p}</p>

const paymentTaken = bookingPayMade;
const basket = session.basket;

if(basket.booking == null){
  return null;
}

if(session.basket.booking.confirmed == 0 && paymentTaken){
  p = [];  
  for(const x of configuration.booking_conf.partial){
    p.push(<>{x}<br></br><br></br></>)
  }
  bookMessage =  <p className='booking-text'>{p}</p>
}
else if(basket.booking.confirmed == 0 && !paymentTaken){
  p = [];  
  for(const x of configuration.booking_conf.nopay){
    p.push(<>{x}<br></br><br></br></>)
  }
  bookMessage =  <p className='booking-text'>{p}</p>
}

let directDebit = false;
let showSummary = false;

for(const dx of basket.deposit_options){
  if(dx.id == basket.booking.payment_option){
    if(dx.force_payment_method == "dd"){
      directDebit = true;
    }
  }
}



if(directDebit){
  directDebit = true;
  if(configuration.booking_conf.payplan != null){
    directDebit = configuration.booking_conf.payplan.direct_debit;
    showSummary = configuration.booking_conf.payplan.show_summary;
    p = [];  
    for(const x of configuration.booking_conf.payplan.m){
      p.push(<>{x}<br></br><br></br></>)
    }
    payPlan =  <p className='booking-text'>{p}</p>
  }
}



if(directDebit){

  const orgName = configuration.booking_conf.payplan.org_name;

  ddSection = <div>

    <div style={{display: 'flex', justifyContent:'space-between', alignItems: 'center'}}>

    <div>
      <a href='https://www.directdebit.co.uk/direct-debit-guarantee/' rel='noreferrer' target='_blank'><p>The Direct Debit Guarantee</p></a>
    </div>

    <div>
    <a href='https://www.directdebit.co.uk/direct-debit-guarantee/' rel='noreferrer' target='_blank'><img src={ddLogo} width={100} alt='Direct Debit Guarantee' /></a>
    </div> 

    </div>

  <p>
    <ul className='debit-list'>
      <li>This Guarantee is offered by all banks and building societies that accept instructions to pay Direct Debits</li>
      <li>If there are any changes to the amount, date or frequency of your Direct Debit {orgName} will notify you 10 working days in advance of your account being debited or as otherwise agreed. If you request {orgName} to collect a payment, confirmation of the amount and date will be given to you at the time of the request</li>
      <li>If an error is made in the payment of your Direct Debit, by {orgName} or your bank or building society, you are entitled to a full and immediate refund of the amount paid from your bank or building society.</li>
      <li>If you receive a refund you are not entitled to, you must pay it back when {orgName} asks you to.</li>
      <li>You can cancel a Direct Debit at any time by simply contacting your bank or building society. Written confirmation may be required. Please also notify us.</li>
    </ul>
  </p>
  </div>
}


let pak = false;
for(const x of session.basket.products){
  if(x.product_type.id == 5){
    pak = true;
  }
}

let bookConf = <DynamicBookingConfirmed bookingData={session} searchedRooms={selectedRooms}></DynamicBookingConfirmed>
if(pak){
  bookConf = <PackageBookingConfirmed bookingData={session} searchedRooms={selectedRooms}></PackageBookingConfirmed>
}



  return (
    <BaseLayout
      onSearchButtonClick={() => setIsSearchBarOpen(!isSearchBarOpen)}
      isBookingConfirmation={true}
      isBookingConfirmed={basket.booking.confirmed}
      userToken={userToken}
      userConfiguration={configuration}
    >



      <div className='booking-confirmed-page'>
        <div className='booking-confirmed-page-wrapper'>
          <SuccessIcon confirmed={basket.booking.confirmed} />

          <div className='booking-confirmed-page-wrapper--header'>
            <p className='booking-title'>
              Dear {basket.lead_person?.title} {basket.lead_person?.first_name}{' '}
              {basket.lead_person?.second_name}
            </p>
            {bookMessage}
            {payPlan}

            {ddSection}
          </div>

          {bookConf}

          {/* <div className='booking-confirmed-page-wrapper--body'>
            <div className='booking-summary-list'>
              {bookingState && (
                <div className='booking-summary-list--item'>
                  <SummaryCard title={'Booking Summary'} icon={docIco} data={bookingState} />
                </div>
              )}
              {<div className='booking-summary-list--item'>{accSec}</div>}
              {flightState && (
                <div className='booking-summary-list--item'>
                  <FlightsCard title={'Flights'} icon={flightIco} flight={flightState} />
                </div>
              )}
              {extraSec}
            </div>
          </div> */}
        </div>
      </div>
    </BaseLayout>
  );
};

export default BookingConfirmed;
