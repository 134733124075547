import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { BaseLayout } from '../../components/BaseLayout';
import { FiltersSidebar } from '../../components/FiltersSidebar/FiltersSidebar';
import { HolidayCard } from '../../components/HolidayCard';
import { PageLoader } from '../../components/PageLoader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  availableBoards,
  availableRatings,
  availableTripRatings,
  availableDestinations,
  availableRegions,
  availableResorts,
  offers,
} from '../../hooks/useRequest';
import { SearchBar } from '../../components/SearchBar';

import useMultipleMedia from '../../hooks/useMultipleMedia';
import { useModal } from '../../hooks/useModal';
import { MapModal } from '../../components/MapModal';
import { Offer } from '../../hooks/types';
import { Button } from '../../components/Button';

import './SearchResultsPage.scss';
import { SortBy } from '../../components/SortBy/SortBy';
import { LoadingModal } from '../../components/FiltersSidebar/LoadingModal';
import { LoadingOverlay } from '../../components/LoadingOverlay/LoadingOverlay';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

import { fbqTrackSearch } from '../../hooks/fb';

import { AvailableCountResultTitle } from '../../hooks/types'

import { getParamsString, getDestinationParamsString, getRegionParamsString, 
  getResortParamsString, getBoardParamsString, getRatingParamsString, getTripRatingParamsString, 
  getSortParamString } from '../../services/search';
import { viewItemList } from '../../services/ggle'
import { clearSessionKeys } from '../../services/session';

export default function SearchResultsPage() {

  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const [searchParams] = useSearchParams();
  const localSession = searchParams.get('session');

  
  const snap = sessionStorage.getItem('snpOff');
  let defaultOffers: any[] = [];
  let defaultPage = 0;
  let defaultSort = 0;
  let defaultCount = 0;
  let defaultOfferID = '';
  let defaultAccList = -1;
  let defaultHasRec = false;

  if (snap != null) {
    const snapO = JSON.parse(snap);

    if(snapO.localSession == localSession){
      defaultOffers = snapO.offers;
      defaultPage = snapO.startIndex;
      defaultSort = snapO.sortIndex;
      defaultCount = snapO.cnt;
      defaultOfferID = snapO.selectedOffer;
      defaultAccList = snapO.sortList;
      defaultHasRec = snapO.hasRecs;
  
      if(snapO.sortList != -1){
        defaultSort = 6;
      }
    }
    else{
      sessionStorage.removeItem('snpOff');
    }
  }
  
  const getBoardObj = (bbID: any) => {
    switch (bbID) {
      case 5:
        return { label: 'All inclusive', value: 5 };
      case 4:
        return { label: 'Full board', value: 4 };
      case 3:
        return{ label: 'Half board', value: 3 };
      case 2:
        return{ label: 'Bed & breakfast', value: 2 };
      case 1:
        return{ label: 'Self catering', value: 1 };
      case 0:
        return{ label: 'Room only', value: 0 };
    }
};

  const navigate = useNavigate();

  const [defaultsDone, setDefaultsDone] = useState<boolean>(false);



  const [boardFilterInformation, setBoardFilterInformation] = useState<any>({});

  const [availBoards, setAvailableBoards] = useState<any>({});
  const [availRatings, setAvailableRatings] = useState<any>({});
  const [availTripRatings, setAvailableTripRatings] = useState<any>({});

  const [availDests, setAvailableDests] = useState<AvailableCountResultTitle[]>([]);
  const [availRegions, setAvailableRegions] = useState<AvailableCountResultTitle[]>([]);
  const [availResorts, setAvailableResorts] = useState<AvailableCountResultTitle[]>([]);

  const isMobile = useMultipleMedia(['(max-width: 768px)'], [true], false);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(true);
  const [isTotalPrice, setIsTotalPrice] = useState(false);

  const [searchItemType, setSearchItemType] = useState<string>();
  const [offerCount, setOfferCount] = useState<number>(0);
  const [hasRecs, setHasRecs] = useState<boolean>(defaultHasRec);

  const [offersData, setOfferData] = useState<Offer[]>([]);
  const [offersFetching, setOfferFetch] = useState<boolean>(true);
  const [offerId, setOfferId] = useState<any>();
  const [isOpen, toggle] = useModal(false);

  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
  const [isMapViewActive, setIsMapViewActive] = useState<boolean>(false);
  const [smallLoader, setMiniLoad] = useState<boolean>(false);

  const [iniLoad, setIniLoad] = useState<boolean>(false);

  const [addOffViewID, setAddOfferViewID] = useState<any>(null);

  const [mapOffer, setMapOffer] = useState<Offer>();

  const [telePhone, setTelephone] = useState<string>('08004084048');

  let selDests : number[] = [];
  let selRegions : number[] = [];
  let selResorts : number[] = [];

  const qsDests = searchParams.get('destinations');
  if(qsDests != null){
    for(const x of qsDests.split('-')){
        selDests.push(Number(x))
    }
  }
  const qsRegions = searchParams.get('regions');
  if(qsRegions != null){
    for(const x of qsRegions.split('-')){
        selRegions.push(Number(x))
    }
  }
  const qsResorts = searchParams.get('resorts');
  if(qsResorts != null){
    for(const x of qsResorts.split('-')){
        selResorts.push(Number(x))
    }
  }

  let departureDate: any = null;
  const qsDeparting = searchParams.get('departing');
  if(qsDeparting != null){
    departureDate = qsDeparting + ' 00:00';
  }

  let departureType = 0;
  const qsDepartingType = searchParams.get('departingType');
  if(qsDepartingType != null){
    departureType = Number(qsDepartingType);
  }
  
  let selDeparturePoints: string[] = [];
  const qsDepPoints = searchParams.get('departurePoints');
  if(qsDepPoints != null){
    for(const x of qsDepPoints.split('-')){
        selDeparturePoints.push(x)
    }
  }

  let duration = 7;
  if(searchParams.get('duration') != null){
    duration = Number(searchParams.get('duration'));
  }


  let selBoards: number[] = [];
  const qsBaords = searchParams.get('boards');
  if(qsBaords != null){
    for(const x of qsBaords.split('-')){
      selBoards.push(Number(x))
    }
  }

  let selRatings: number[] = [];
  const qsRates = searchParams.get('ratings');
  if(qsRates != null){
    for(const x of qsRates.split('-')){
      selRatings.push(Number(x))
    }
  }  
  
  let selTripRatings: number[] = [];
  const qsTrRates = searchParams.get('tratings');
  if(qsTrRates != null){
    for(const x of qsTrRates.split('-')){
      selTripRatings.push(Number(x))
    }
  }

  const defaultSortOptions = [
    {
      label: 'Lowest Price',
      value: 0,
    },
    {
      label: 'Highest Price',
      value: 1,
    },
    {
      label: 'Star Rating',
      value: 3,
    },
    {
      label: 'Tripadvisor Rating',
      value: 5,
    },
  ];

  if(configuration.rec_acc != null){
    if(configuration.rec_acc != -1){
      if(hasRecs){
        defaultSortOptions.push({
          label: 'Recommended',
          value: 6,
        })
      }
    }
  }


  let selSort = defaultSort;
  const qsSort = searchParams.get('sort');
  if(qsSort != null){
    selSort = Number(qsSort);
    if(selSort == 6){
      defaultAccList = 1;
    }
    else{
      defaultAccList = -1;
    }
  }


  const [startIndex, setStartIndex] = useState<number>(defaultPage);

  const sortIndex = selSort;
  const accList = defaultAccList;

  const [sortOptions, setSortOptions] = useState<any[]>(defaultSortOptions);

  const setSort = (sortIndex: number) => {
    
    const strParams = getParamsString(searchParams);

    const strDest = getDestinationParamsString(searchParams);
    const strReg = getRegionParamsString(searchParams);
    const strRes = getResortParamsString(searchParams);
    
    const strBoards = getBoardParamsString(searchParams);
    const strRates = getRatingParamsString(searchParams);
    const strTripRates = getTripRatingParamsString(searchParams);


    navigate(`/search?${strParams + strDest + strReg + strRes + strBoards + strRates + strTripRates + '&sort=' +sortIndex}`, {replace: true});


  }


  const getTripRatingValues = (tripRatingValues: any) => {
    if (!tripRatingValues) {
      return [];
    }

    const ratingFilters: any = [];

    let tempValue = 0;
    tripRatingValues.forEach((item: any) => {
      const ratingValue = Object.assign({}, item);
      ratingValue.count = item.count;
      ratingFilters.push(ratingValue);

      tempValue = item.count;
    });

    return ratingFilters;
  };


  let favAcc = '[]';
  const qsAcc = searchParams.get('acc');
  if (qsAcc != null) {
    favAcc = `[${qsAcc}]`;
  }

  useEffect(() => {
    sessionStorage.removeItem('session_cr');
   
    const clearPrevSearch = searchParams.get('clrPrvSrch');
    if(clearPrevSearch != null){
        if(clearPrevSearch == '1'){
          localStorage.removeItem('prevsrch');
          localStorage.removeItem('prevsrchCityOps');
        }
    }


  }, [])
  
  useEffect(() => {

    const defaultSortOptions = [
      {
        label: 'Lowest Price',
        value: 0,
      },
      {
        label: 'Highest Price',
        value: 1,
      },
      {
        label: 'Star Rating',
        value: 3,
      },
      {
        label: 'Tripadvisor Rating',
        value: 5,
      },
    ];

    if(hasRecs){
      if(configuration.rec_acc != null){
        if(configuration.rec_acc != -1){
          if(hasRecs){
            defaultSortOptions.push({
              label: 'Recommended',
              value: 6,
            })
          }
        }
      }
      setSortOptions(defaultSortOptions)
    }
    else{
      setSortOptions(defaultSortOptions)
    }
  }, [hasRecs]);

  // useEffect(() => {
  //   isSearchBarOpen && !isMobile
  //     ? (document.body.style.overflow = 'hidden')
  //     : (document.body.style.overflow = 'scroll');
  // }, [isSearchBarOpen]);

  let rooms = searchParams.get('rooms') || '2-';

  useEffect(() => {

    setSearchItemType('');
    sessionStorage.setItem('lastSearchResults', window.location.href)
    
    getAvailBoards(
      selDeparturePoints,
      rooms,
      duration,
      departureDate,
      departureType,
      selDests,
      selResorts,
      selRegions,
      selRatings,
      selTripRatings,
    ).then((d) => {
      if (d.available_boards.result) {
        setAvailableBoards(d.available_boards.result);
      }
      getAvailRatings(
        selDeparturePoints,
        rooms,
        duration,
        departureDate,
        departureType,
        selDests,
        selResorts,
        selRegions,
        selBoards,
        selTripRatings,
      ).then((d) => {
        if (d.available_ratings.result) {
          setAvailableRatings(d.available_ratings.result);
        }

        getAvailTripRatings(
          selDeparturePoints,
          rooms,
          duration,
          departureDate,
          departureType,
          selDests,
          selResorts,
          selRegions,
          selRatings,
          selBoards,
        ).then((d) => {
          if (d.available_trip_ratings.result) {
            setBoardFilterInformation((prevState: any) => ({
              ...prevState,
              tripRatings: getTripRatingValues(d.available_trip_ratings.result),
            }));
            setAvailableTripRatings(d.available_trip_ratings.result);
          }
        });
      });

      if(selDests.length == 0){
        setAvailableRegions([])
      }
      else{
        getAvailRegions(selDeparturePoints, rooms, duration, departureDate, departureType, [], selDests, selRatings, selTripRatings, selBoards).then((d) => {
            setAvailableRegions(d.available_regions.result)
        })
      }

      if(selRegions.length == 0){
        setAvailableResorts([])
      }
      else{
        getAvailResorts(selDeparturePoints, rooms, duration, departureDate, departureType, [], selRegions, selRatings, selTripRatings, selBoards).then((d) => {
            setAvailableResorts(d.available_resorts.result)
        })
      }

      getAvailDests(selDeparturePoints, rooms, duration, departureDate, departureType, [], [], selRatings, selTripRatings, selBoards).then((d) => {
          setAvailableDests(d.available_destinations.result)
      });

      if (!iniLoad && defaultOffers.length > 0) {
        setOfferData(defaultOffers);
        setOfferFetch(false);
        setOfferCount(defaultCount);
        setIniLoad(true);
      } else {
        setMiniLoad(true);

        getOffers(
          0,
          selDeparturePoints,
          rooms,
          duration,
          departureDate,
          departureType,
          selDests,
          selResorts,
          selRegions,
          selBoards,
          selRatings,
          selTripRatings,
          accList,
          sortIndex
        ).then((d) => {
          setOfferCount(d.offers.count);
          setOfferData(d.offers.result);
          setMiniLoad(false);
          setOfferFetch(false);
          setStartIndex(0);
          setIniLoad(true);

          if(d.offers.found_lists.length > 0){
            setHasRecs(true);
          }
          else{
            setHasRecs(false);
          }
          
          fbq(d.offers.result);

          // viewItemList(d.offers.result, configuration);

        });
      }
    });
  }, [searchParams]);

  const fbq = (offers: any) => {

    if(offers == null){
      return
    }

    if(offers.length == 0){
      return
    }

    const contentIDS = []
    for(const o of offers){
      contentIDS.push("'" + o.accommodation.id + "'");
    }

    const strCIDS = '[' + contentIDS.join(',') + ']';

    const o = offers[0]
    const cin = dayjs(o.flights[0].arrival).format('YYYY-MM-DD');
    const cout = dayjs(o.flights[0].return_departing).format('YYYY-MM-DD');
    
    fbqTrackSearch(o.accommodation.resort.name, o.accommodation.resort.regions[0].name, o.accommodation.resort.regions[0].destinations[0].name, 
      cin, cout, strCIDS , o.rooms[0].adults, o.rooms[0].children)

  }

  useEffect(() => {
    if (defaultsDone) {
      return;
    }


    window.location.href = '#' + defaultOfferID;
  }, [offersData]);

  const snapOffers = (id: string) => {
    const x = {
      offers: offersData,
      selectedOffer: id,
      startIndex: startIndex,
      sortIndex: sortIndex,
      cnt: offerCount,
      sortList: accList,
      hasRecs: hasRecs,
      localSession: localSession,
    };
    sessionStorage.setItem('snpOff', JSON.stringify(x));
    // sessionStorage.setItem('aprm', JSON.stringify(actualParams));

  };

  const additionalOfferViewID = (id: string) => {
    setAddOfferViewID(id);
  }

  let stype = searchParams.get('type');
  let searchType = 0;
  if(stype !=null){ 
    searchType = Number(stype);
  }

  const getOffers = async (
    sIndex = 0,
    selDeparturePoints: string[],
    persons: any,
    duration: number,
    departureDate: string,
    departureType: number,
    destinations: number[],
    resorts: number[],
    regions: number[],
    boards: number[],
    ratings: number[],
    tRatings: number[],
    clientAccList = -1,
    sort=0,
  ) => {

    if(sort == 6){
      sort = 0;
    }

    const x = await offers(
      userToken,
      searchType,
      selDeparturePoints,
      destinations,
      regions,
      resorts,
      departureDate,
      duration,
      persons,
      sIndex === 0 ? 0 : sIndex * 25,
      ratings,
      boards,
      departureType,
      tRatings,
      [],
      sort,
      undefined,
      clientAccList
    );
    setDefaultsDone(true);

    clearSessionKeys(['snpOff', 'js1', 'js2', 'js3', 'js4', 'js5', 'shosnt', 'gap']);
    
    return x;
  };

  const getAvailBoards = async (
    departurePoints: string[],
    rooms: string,
    duration: number,
    departureDate: any,
    departureType: any,
    destinations: number[],
    resorts: number[],
    regions: number[],
    ratings: number[],
    tRatings: number[],
  ) => {
    return await availableBoards(
      userToken,
      rooms,
      departurePoints,
      duration,
      ratings,
      tRatings,
      departureDate,
      departureType,
      regions,
      resorts,
      destinations,
      searchType,
      null,
    );
  };

  const getAvailRatings = async (
    departurePoints: string[],
    rooms: any,
    duration: any,
    departureDate: any,
    departureType: any,
    destinations: any,
    resorts: any,
    regions: any,
    boards: any,
    tRatings: any,
  ) => {

    return await availableRatings(
      userToken,
      rooms,
      departurePoints,
      duration,
      boards,
      tRatings,
      departureDate,
      departureType,
      regions,
      resorts,
      destinations,
      searchType,
    );
  };

  const getAvailTripRatings = async (
    departurePoints: string[],
    rooms: any,
    duration: any,
    departureDate: any,
    departureType: any,
    destinations: any,
    resorts: any,
    regions: any,
    ratings: any,
    boards: any,
  ) => {

    return await availableTripRatings(
      userToken,
      rooms,
      departurePoints,
      duration,
      boards,
      ratings,
      departureDate,
      departureType,
      regions,
      resorts,
      destinations,
      searchType,
    );
  };

  const getAvailDests = async (
    departurePoints: string[],
    rooms: any,
    duration: any,
    departureDate: any,
    departureType: any,
    resorts: any,
    regions: any,
    ratings: any,
    tRatings: any,
    boards: any,
  ) => {
    return await availableDestinations(
      userToken,
      rooms,
      departurePoints,
      duration,
      boards,
      ratings,
      tRatings,
      departureDate,
      departureType,
      regions,
      resorts,
      searchType,
    );
  };

  const getAvailRegions = async (
    departurePoints: string[],
    rooms: any,
    duration: any,
    departureDate: any,
    departureType: any,
    resorts: number[],
    dests: number[],
    ratings: number[],
    tRatings: number[],
    boards: number[],
  ) => {
    return await availableRegions(
      userToken,
      rooms,
      departurePoints,
      duration,
      boards,
      ratings,
      tRatings,
      departureDate,
      departureType,
      dests,
      resorts,
      searchType,
    );
  };

  const getAvailResorts = async (
    departurePoints: string[],
    rooms: any,
    duration: any,
    departureDate: any,
    departureType: any,
    dests: number[],
    regions: number[],
    ratings: number[],
    tRatings: number[],
    boards: number[],
  ) => {
    return await availableResorts(
      userToken,
      rooms,
      departurePoints,
      duration,
      boards,
      ratings,
      tRatings,
      departureDate,
      departureType,
      dests,
      regions,
      searchType,
    );
  };

  const boardChange = (board: number) => {
    const strParams = getParamsString(searchParams)

    const strDest = getDestinationParamsString(searchParams);
    const strReg = getRegionParamsString(searchParams);
    const strRes = getResortParamsString(searchParams);

    const strRates = getRatingParamsString(searchParams);
    const strTripRates = getTripRatingParamsString(searchParams);
    const sorts = getSortParamString(searchParams);

    const index = selBoards.indexOf(board);
    if(index != -1){
      selBoards.splice(index, 1);
    }
    else{
      selBoards.push(board);
    }
    let strBoards = '';
    if(selBoards.length > 0){
      strBoards = '&boards=' + selBoards.join('-');
    }
    navigate(`/search?${strParams + strDest + strReg + strRes + strBoards + strRates + strTripRates + sorts}`, {replace: true});
  }

  const ratingChange = (r: number) => {
    const strParams = getParamsString(searchParams);

    const strDest = getDestinationParamsString(searchParams);
    const strReg = getRegionParamsString(searchParams);
    const strRes = getResortParamsString(searchParams);

    const strBoards = getBoardParamsString(searchParams);
    const strTripRates = getTripRatingParamsString(searchParams);
    const sorts = getSortParamString(searchParams);
    
    const index = selRatings.indexOf(r);
    if(index != -1){
      selRatings.splice(index, 1);
    }
    else{
      selRatings.push(r);
    }
    let strRates = '';
    if(selRatings.length > 0){
      strRates = '&ratings=' + selRatings.join('-');
    }
    navigate(`/search?${strParams + strDest + strReg + strRes + strBoards + strRates + strTripRates + sorts}`, {replace: true});
  }

  const tripRatingChange = (tr: number) => {
    const strParams = getParamsString(searchParams);

    const strDest = getDestinationParamsString(searchParams);
    const strReg = getRegionParamsString(searchParams);
    const strRes = getResortParamsString(searchParams);
    
    const strBoards = getBoardParamsString(searchParams);
    const strRates = getRatingParamsString(searchParams);
    const sorts = getSortParamString(searchParams);

    const index = selTripRatings.indexOf(tr);
    if(index != -1){
      selTripRatings.splice(index, 1);
    }
    else{
      selTripRatings.push(tr);
    }
    let strTripRates = '';
    if(selTripRatings.length > 0){
      strTripRates = '&tratings=' + selTripRatings.join('-');
    }
    navigate(`/search?${strParams + strDest + strReg + strRes + strBoards + strRates + strTripRates + sorts}`, {replace: true});
  }

  const destinationChange = (d: number) => {
    const strParams = getParamsString(searchParams)

    const strReg = getRegionParamsString(searchParams);
    const strRes = getResortParamsString(searchParams);

    const strBoards = getBoardParamsString(searchParams);
    const strRates = getRatingParamsString(searchParams);
    const strTripRates = getTripRatingParamsString(searchParams);
    const sorts = getSortParamString(searchParams);

    const index = selDests.indexOf(d);
    if(index != -1){
      selDests.splice(index, 1);
    }
    else{
      selDests.push(d);
    }
    let strDest = '';
    if(selDests.length > 0){
      strDest = '&destinations=' + selDests.join('-');
    }
    navigate(`/search?${strParams + strDest + strReg + strRes + strBoards + strRates + strTripRates + sorts}`, {replace: true});
  }

  const regnioChange = (d: number) => {
    const strParams = getParamsString(searchParams)

    const strDest = getDestinationParamsString(searchParams);
    const strRes = getResortParamsString(searchParams);
    
    const strBoards = getBoardParamsString(searchParams);
    const strRates = getRatingParamsString(searchParams);
    const strTripRates = getTripRatingParamsString(searchParams);
    const sorts = getSortParamString(searchParams);
    
    const index = selRegions.indexOf(d);
    if(index != -1){
      selRegions.splice(index, 1);
    }
    else{
      selRegions.push(d);
    }
    let strReg = '';
    if(selRegions.length > 0){
      strReg = '&regions=' + selRegions.join('-');
    }
    navigate(`/search?${strParams + strDest + strReg + strRes + strBoards + strRates + strTripRates + sorts}`, {replace: true});
  }

  const resortChange = (d: number) => {
    const strParams = getParamsString(searchParams)

    const strDest = getDestinationParamsString(searchParams);
    const strReg = getRegionParamsString(searchParams);
    
    const strBoards = getBoardParamsString(searchParams);
    const strRates = getRatingParamsString(searchParams);
    const strTripRates = getTripRatingParamsString(searchParams);
    const sorts = getSortParamString(searchParams);

    const index = selResorts.indexOf(d);
    if(index != -1){
      selResorts.splice(index, 1);
    }
    else{
      selResorts.push(d);
    }
    let strRes = '';
    if(selResorts.length > 0){
      strRes = '&resorts=' + selResorts.join('-');
    }
    navigate(`/search?${strParams + strDest + strReg + strRes + strBoards + strRates + strTripRates + sorts}`, {replace: true});
  }

  const onMapLinkClickHandler = (offer: Offer) => {
    // setOfferId(id);
    // toggle();

    setMapOffer(offer)
    toggle();
  };

  useEffect(() => {
    if (!iniLoad) {
      return;
    }

    const rooms = searchParams.get('rooms');




    let sort = sortIndex;
    if(accList != -1){
      sort = 0;
    }

    setMiniLoad(true);
    getOffers(
      startIndex,
      selDeparturePoints,
      rooms,
      duration,
      departureDate,
      departureType,
      selDests,
      selResorts,
      selRegions,
      selBoards,
      selRatings,
      selTripRatings,
      accList,
      sort
    ).then((d) => {
      setOfferCount(d.offers.count);
      setOfferData(d.offers.result);

      if(d.offers.found_lists.length > 0){
        setHasRecs(true);
      }
      else{
        setHasRecs(false);
      }

      setMiniLoad(false);

      
    });
  }, [sortIndex, accList]);

  function handleNextPage() {
    if (!iniLoad) {
      return;
    }
 
    let departureType = 0;
    const qsDepartingType = searchParams.get('departingType');
    if(qsDepartingType != null){
      departureType = Number(qsDepartingType);
    }

    setMiniLoad(true);
    getOffers(
      startIndex + 1,
      selDeparturePoints,
      rooms,
      duration,
      departureDate,
      departureType,
      selDests,
      selResorts,
      selRegions,
      selBoards,
      selRatings,
      selTripRatings,
      accList,
      sortIndex
    ).then((d) => {
      setOfferCount(d.offers.count);
      setOfferData([...offersData, ...d.offers.result]);

      if(d.offers.found_lists.length > 0){
        setHasRecs(true);
      }
      else{
        setHasRecs(false);
      }


      setMiniLoad(false);
      setStartIndex(startIndex + 1);
    });
  }

  function toggleFilterModal() {
    setIsFiltersOpen(!isFiltersOpen);
  }

  let xOverlayLoad = null;
  if (smallLoader) {
    xOverlayLoad = <LoadingOverlay ringColour={configuration.button_colour} buttonText={configuration.button_text_colour}></LoadingOverlay>;
  }

  let mapSec = null;
  if(mapOffer && isOpen){
    mapSec = <MapModal
    offer={mapOffer}
    isOpen={isOpen}
    toggle={toggle}
    searchParams={searchParams}
    onTotalPriceChange={(e: any) => setIsTotalPrice(e)}
    isTotalPriceFlag={isTotalPrice}
  />
  }

  return (
    <BaseLayout
      isOverlayOpen={isSearchBarOpen}
      onSearchItemClick={setSearchItemType}
      searchParams={searchParams}
      onSearchButtonClick={() => {
        // setIsSearchBarOpen(!isSearchBarOpen);
      }}
      userToken={userToken}
      userConfiguration={configuration}
      showSearch={true}
      greyMode={true}
    >
      <div className='search-results-page'>
        {offersFetching ? (
          <>
            <PageLoader config={configuration} />
            {isFiltersOpen && <LoadingModal isModalOpen />}
            {isMapViewActive && (
              <div style={{ flex: 1 }}>
                <LoadingOverlay background='#fff' />
              </div>
            )}
          </>
        ) : (
          <>
            {xOverlayLoad}
            {/* <div className={isSearchBarOpen ? 'search-bar-wrapper' : 'search-bar-wrapper hidden'}>
              <SearchBar
                searchItemType={searchItemType}
                isBoardType={false}
                isSearchPage={true}
                resultFilters={null}
                onClick={() => {
                  // setIsSearchBarOpen(!isSearchBarOpen);
                }}
              />
            </div> */}

            <div className='search-bar-wrapper search-bar-wrapper--no-index'>
              <div className='search-page-top-wrapper'>
                <h1 className='search-page-main-heading'>{offerCount} Holidays Found</h1>

                <div className='search-page-top-wrapper__btn-wrap'>
                  {/* <div>
                    <MapViewTrigger onClick={() => setIsMapViewActive(!isMapViewActive)} />
                  </div> */}



                  <SortBy
                    title='Sort by'
                    variants={sortOptions}
                    defaultValue={sortIndex}
                    callback={(index) => {
                      setSort(index);
                    }}
                  />
                </div>
              </div>

              <div className={'search-results-container'}>
                <FiltersSidebar
                  onTotalPriceChange={(e: any) => setIsTotalPrice(e)}
                  isModalOpen={isFiltersOpen}
                  toggleModal={toggleFilterModal}
                  isTotalPriceFlag={isTotalPrice}
                  onBoardChange={(b) => boardChange(b)}
                  onRatingChange={(b) => ratingChange(b)}
                  onTripRatingChange={(b) => tripRatingChange(b)} 
                  onDestChange={(b) => destinationChange(b)}
                  onRegionChange={(b) => regnioChange(b)}
                  onResortChange={(b) => resortChange(b)}
                  sortOptions={sortOptions}
                  onSortChange={(index: number) => setSort(index)}
                  activeOption={sortIndex}
                  boards={availBoards}
                  ratings={availRatings}
                  tripRatings={availTripRatings}
                  selectedBoards={selBoards}
                  selectedRatings={selRatings}
                  selectedTripRatings={selTripRatings}
                  destinations={availDests}
                  regions={availRegions}
                  resorts={availResorts}
                  selectedDestinations={selDests}
                  selectedRegions={selRegions}
                  selectedResorts={selResorts}
                />

                {!isMapViewActive && (
                  <div style={{backgroundColor: configuration.container_colour}} className='filter-sidebar-button' role='button' onClick={toggleFilterModal}>
                    <p>Sort &amp; Filter</p>
                    <svg
                      width='1rem'
                      height='1rem'
                      version='1.1'
                      viewBox='0 0 1200 1200'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g fill='#fff'>
                        <path d='m1096 304.25c0 39.898-32.34 72.254-72.254 72.254h-847.48c-39.898 0-72.254-32.34-72.254-72.254 0-39.898 32.34-72.254 72.254-72.254h847.5c39.898 0.003906 72.238 32.355 72.238 72.254z' />
                        <path d='m976 607.88c0 39.898-32.34 72.254-72.254 72.254h-607.48c-39.898 0-72.254-32.34-72.254-72.254 0-39.898 32.34-72.254 72.254-72.254h607.5c39.898 0.003906 72.238 32.355 72.238 72.254z' />
                        <path d='m796 911.51c0 39.898-32.34 72.254-72.254 72.254h-247.48c-39.898 0-72.254-32.34-72.254-72.254 0-39.898 32.34-72.254 72.254-72.254h247.5c39.898 0.003906 72.238 32.355 72.238 72.254z' />
                      </g>
                    </svg>
                  </div>
                )}

                <div className={'holiday-card-container'}>
                  {offersData && !isFiltersOpen &&
                    offersData.map((offer: any, i: number) => {

                      let addOffs = false;
                      if(offer.id == addOffViewID){
                        addOffs = true;
                      }

                      let rec = false;
                      if(configuration.rec_acc != null){
                        if(configuration.rec_acc != -1){
                          for(const x of offer.accommodation.lists){
                            if(x == configuration.rec_acc){
                              rec = true;
                            }
                          }
                        }
                      }

                      return (
                        <HolidayCard
                          id={offer.id}
                          offer={offer}
                          isTotalPriceFlag={isTotalPrice}
                          searchParams={searchParams || null}
                          selectedBoards={selBoards}
                          onMapLinkClick={() => onMapLinkClickHandler(offer)}
                          onOfferClick={(id) => snapOffers(id)}
                          onShowOfferClick={(id) => additionalOfferViewID(id)}
                          isMap={false}
                          isRec={rec}
                          additionalOffersVisible={addOffs}
                        />
                      );
                    })}

                  {offerCount && offersData.length !== 0 && offersData.length < offerCount && (
                    <Button onClick={handleNextPage} label='Load More' size='large' borderColor={configuration.button_colour_tertiary} backgroundColor={configuration.button_colour_tertiary} color={configuration.button_text_tertiary_colour} />
                  )}
                </div>
              </div>
            </div>

            {mapSec}

          </>
        )}
      </div>
    </BaseLayout>
  );
}
