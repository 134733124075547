import React, { FunctionComponent, useEffect, useState } from 'react';
import cn from 'classnames';

import AirportsSelect from './AirportsSelect';
import DestinationSelect from './DestinationSelect';
import RoomsSelect from './RoomsSelect';
import DurationSelect from './DurationSelect';
import { Datepicker } from '../Datepicker';
import './SearchBar.scss';
import { Button } from '../Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BoardTypeSelect from './BoardType';

import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';


import flightHotelIcon from '../assets/flightHotel.svg';
import cityIcon from '../assets/city.svg';
import packageIcon from '../assets/pack.svg';

import { useLocationQuery } from '../../hooks/useQuery';

import search from '../../components/assets/search-icon.svg';
import searchDark from '../../components/assets/icons/dark/noun-magnifying-glass-202232-222222.svg';

import { fbqTrackSearch } from '../../hooks/fb';
import { searchAdd, siteConfiguration } from '../../hooks/useRequest';

import { LoaderSpinner } from '../Loader/jLoader'
import dayjs from 'dayjs';

import { SearchSelectionRoom, SearchSelections, Agent } from '../../hooks/types'

import { roomsFromPaxString } from '../../services/pax';
import { geoArrayFromString, departurePointArrayFromString, departurePointIdFromCode } from '../../services/geo';
import { sessionSetSearchParams, sessionGetSearchParams } from '../../services/search';
import { clearSessionKeys, getCookie } from '../../services/session';
import { siteNavigate } from '../../services/nav'

interface PackageRefineProps {
  isBoardType?: boolean;
  isHolidayPage?: boolean;
  isSearchPage?: boolean;
  searchItemType?: string;
  localSession?: string;
  accommodationId?: number;
  resultFilters?: any;
  showInnnerLabels?: boolean;
  onClick?: () => any;
}



const PackageRefine: FunctionComponent<PackageRefineProps> = ({
  isBoardType = false,
  isSearchPage = false,
  isHolidayPage = false,
  searchItemType,
  accommodationId,
  showInnnerLabels = true,
  onClick
}) => {


  const {
    RootStore: {
      sessionStore: { userSession, createUserSession },
      userToken,
      configuration,
      agent,
    },
  } = useStores() as { RootStore: RootStore };

  const [searchParams] = useSearchParams();

  const [showPackageCityTab, setShowPackageCity] = useState<boolean>(true);


  

  useEffect(() => {

    let defaultSelections: SearchSelections = { 
    duration: 7, destinations: [], regions: [], resorts: [], 
    departurePoints: [], departureDate: null, departureDateType: 0, searchType: 2, ratings: [], tripRatings: [], boards: [], rooms : [{adults: 2, children: 0, childAges: []}]}


    if(searchParams != null){
      if(searchParams.get('departing') != null){
        defaultSelections.departureDate = searchParams.get('departing') + ' 00:00';
      }   
      
      if(searchParams.get('departingType') != null){
        defaultSelections.departureDateType = Number(searchParams.get('departingType'));
      }
  
      if(searchParams.get('rooms') != null){
        defaultSelections.rooms = roomsFromPaxString(searchParams.get('rooms') || '');
      }
      if(searchParams.get('duration') != null){
        defaultSelections.duration = Number(searchParams.get('duration'));
      }

      if(searchParams.get('destinations') != null){
        defaultSelections.destinations = geoArrayFromString(searchParams.get('destinations'));
      }
  
      if(searchParams.get('regions') != null){
        defaultSelections.regions = geoArrayFromString(searchParams.get('regions'));
      }
  
      if(searchParams.get('resorts') != null){
        defaultSelections.resorts = geoArrayFromString(searchParams.get('resorts'));
      }
      
      if(searchParams.get('departurePoints') != null){
        defaultSelections.departurePoints = departurePointArrayFromString(searchParams.get('departurePoints'));
      }
  
    }

    setSelectedSearchParams(defaultSelections);
    setReady(true);
  }, [searchParams])


  const [selectedSearchParams, setSelectedSearchParams] = useState<SearchSelections>()

  const [maxGuestsMessage, setMaxGuestsMessage] = useState<string | null>(null);

  const [formError, setFormError] = useState<{ key: string; value: string }>();
  const [depAirVal, setDepAirVal] = useState<{ valid: boolean; message: string }>();
  const [dateVal, setDateVal] = useState<{ valid: boolean; message: string }>();
  const [paxVal, setPaxVal] = useState<{ valid: boolean; message: string }>();

  const [ready, setReady] = useState<boolean>(false);

  const [searchAtt, setSearchAtt] = useState<boolean>(false);

  const fNav = useNavigate();

  function setZIndex(elem: string) {
    const child = document.getElementById(elem);

    if(child == null){
      return
    }

    const parentWithClass = child.closest('.stack') as HTMLElement;
    if(parentWithClass == null){
      return
    }

    parentWithClass.style.zIndex = '999';
  }

  function handleOptionSelection(searchType: number) {

    if(selectedSearchParams == null){
      return
    }
    const sp = selectedSearchParams;

    setDepAirVal({valid: true, message: ''});
    setPaxVal({valid: true, message: ''});
    setDateVal({valid: true, message: ''});

    setSearchAtt(false);
    // setSearchType(searchType);
    setFormError(undefined);

 
    sp.searchType = searchType;
    
    if(searchType == 1 || searchType == 3){
      sp.duration = 3;
    }
    else{
      sp.duration = 7;
    }

    sp.destinations.length = 0;
    sp.regions.length = 0;
    sp.resorts.length = 0;
    sp.departurePoints.length = 0;
    sp.departureDate = null;
    sp.departureDateType = 0;
    sp.rooms = [{adults: 2, children: 0, childAges: []}];
    sessionStorage.removeItem('roomSelections');
    setSelectedSearchParams(sp);
  }

  if(selectedSearchParams == null){
    return null;
  }


  function handleDeparturePointParams(selectedValues:string[]){
    if(selectedSearchParams == null){
      return
    }
    const sp = selectedSearchParams;
    sp.departurePoints = selectedValues;
    setSelectedSearchParams(sp);
    validateInputs(false);
  }

  function handleDestinationParams(selectedDestinatons: number[], selectedRegions: number[], selectedResorts: number[]){
    if(selectedSearchParams == null){
      return
    }
    const sp = selectedSearchParams;
    sp.destinations = selectedDestinatons;
    sp.regions = selectedRegions;
    sp.resorts = selectedResorts;
    setSelectedSearchParams(sp);
    validateInputs(false);

  }

  function handleDurationParam(option:any){
    if(selectedSearchParams == null){
      return
    }
    const sp = selectedSearchParams;
    sp.duration = option
    setSelectedSearchParams(sp);
  }

  function handleDateParam(selectedValues: string | null){

 
    if(selectedSearchParams == null){
      return
    }
    const sp = selectedSearchParams;
    sp.departureDate = selectedValues;
    sp.departureDateType = 0;
    setSelectedSearchParams(sp);
    validateInputs(false);
  }

  function handleDateMonthParam(selectedValues: string | null){
    if(selectedSearchParams == null){
      return
    }
    const sp = selectedSearchParams;
    sp.departureDate = selectedValues;
    sp.departureDateType = 1;
    setSelectedSearchParams(sp);
  }


  function handleRoomsParam(selectedValues: SearchSelectionRoom[]){
    if(selectedSearchParams == null){
      return
    }
    const sp = selectedSearchParams;
    sp.rooms = selectedValues;
    setSelectedSearchParams(sp);
  }


  const handleOnSearchClick = async () => {
    
    setSearchAtt(true);

    const valDepAir = {valid: true, message: ''};
    const valDest = {valid: true, message: ''};
    const valDate = {valid: true, message: ''};
    const valPax = {valid: true, message: ''};
    let valid = true;

    if (Object.keys(selectedSearchParams).length > 0 && selectedSearchParams.rooms) {
      const { rooms } = selectedSearchParams;
      // Loop through each room object and add the number of adults and children together
      // If the total is greater than 4 then set valid to false
      rooms.forEach((room: any) => {
        if (room.adults + room.children > 5) {
          valid = false;
        }
      });
    }
    


    if (!valid) {
      setMaxGuestsMessage(
        'You may only have a maximum of 5 guests per room. Please amend your search and try again.',
      );
    } else {

      const strSelectedSearchParams = JSON.stringify(selectedSearchParams)

      if (!isSearchPage && !isHolidayPage) {
  
        clearSessionKeys(['f', 'aprm', 'js1', 'js2', 'js3', 'js4', 'js5', 'shosnt', 'gap']);
      }

      sessionStorage.removeItem('snpOff');

      const rooms = selectedSearchParams.rooms;
      let adults = 0;
      let children = 0;
      for(const x of rooms as any){
        adults += x.adults;
        children += x.children;
      }

      let strDepDate = '';
      if(selectedSearchParams.departureDate != null){
        const depDateObj = dayjs(selectedSearchParams.departureDate)
        strDepDate = depDateObj.format('YYYY-MM-DD');
      }

      const selectedDeparturePoints:any[] = []
      if(selectedSearchParams.departurePoints != null){
        for(const a of selectedSearchParams.departurePoints){
          selectedDeparturePoints.push(a);
        }
      }

      const d: number[] = [];
      const g: number[] = [];
      const s: number[] = [];
      if(selectedSearchParams.destinations != null){
        for(const x of selectedSearchParams.destinations){
            d.push(Number(x));
        }
      }

      if(selectedSearchParams.regions != null){
        for(const x of selectedSearchParams.regions){
          g.push(Number(x));
        }
      }

      if(selectedSearchParams.resorts != null){
        for(const x of selectedSearchParams.resorts){
          s.push(Number(x));
        }
      }

      const valid = validateInputs(true);

      const strRoom = [];
      for(const x of selectedSearchParams.rooms){
        const cas: any[] = [];
        for(const ca of x.childAges){
          cas.push(ca)
        }
        strRoom.push(x.adults + '-' + cas.join(','))
      }

      const qsRooms = strRoom.join('_')
      
      let queryString = 'rooms=' + qsRooms + '&duration=' + selectedSearchParams.duration;

      if(strDepDate != ''){
        queryString += '&departing=' + strDepDate;
        queryString += '&departingType=' + selectedSearchParams.departureDateType;
      }

      if(s.length > 0){
        queryString += '&resorts=' + s.join('-'); 
      }

      if(g.length > 0){
        queryString += '&regions=' + g.join('-'); 
      }
      if(d.length > 0){
        queryString += '&destinations=' + d.join('-'); 
      }

      if(selectedDeparturePoints.length > 0){
        queryString += '&departurePoints=' + selectedDeparturePoints.join('-'); 
      }

      queryString += '&type=' + selectedSearchParams.searchType; 


      sessionSetSearchParams(selectedSearchParams);

      const da: number[] = [];

      for(const x of selectedSearchParams.departurePoints){
        const dp = departurePointIdFromCode(x);
        if(dp == null){
          continue;
        }
        da.push(dp);
      }

    if (valid) {
        const path = window.location.pathname;
        window.location.href = `${path}?${queryString}`;
    }
  };
  }



  const validateInputs = (searchAttempt: boolean) => {
    let valid = true;

    // const params = {
    //   ...selectedSearchParams,
    //   // ...parsedSearchParams,
    //   type: state.option,
    // };

    const valDepAir = {valid: true, message: ''};
    const valDest = {valid: true, message: ''};
    const valDate = {valid: true, message: ''};
    const valPax = {valid: true, message: ''};

    // Package specific validations
    if (!selectedSearchParams.departurePoints || selectedSearchParams.departurePoints?.length === 0) {
    valid = false;
    valDepAir.valid = false;
    valDepAir.message = 'Please select an airport';
    }
    else{
    valDepAir.valid = true;
    valDepAir.message = '';
    }

    if (!selectedSearchParams.departureDate) {
    valid = false;
    valDate.valid = false;
    valDate.message = 'Please select a date';
    }else{
    valDate.valid = true;
    valDate.message = '';
    }


  // Always validate children ages (for any type of search)
  if (selectedSearchParams.rooms) {
  let noChildAge = 0;
  const rooms = selectedSearchParams.rooms as any;
  for(const x of rooms){
    const childAgesArray = x.childAges;
    for(const ca of childAgesArray){
      if(Number(ca) < 0){
        noChildAge++;
        break;
      }
    }
  }
  if(noChildAge > 0){
    valid = false;
    valPax.valid = false
    if(noChildAge == 1){
      valPax.message = 'Please select your childs age on return';
    }
    else{
      valPax.message = 'Please select your childrens ages on return';
    }
  }
  else{
    valPax.valid = true
    valPax.message = '';
  }
  }
  


  if(searchAtt || searchAttempt){
    setDepAirVal(valDepAir);
    setPaxVal(valPax);
    setDateVal(valDate);
  }

  return valid
}

  let rooms = JSON.stringify(['2']);
  let numberOfRooms = 1;
  if (selectedSearchParams) {
    if (selectedSearchParams.rooms) {
      rooms = JSON.stringify(selectedSearchParams.rooms);
      numberOfRooms = selectedSearchParams.rooms.length;
    }
  }

  // const availableParams =
  //   Object.keys(parsedSearchParams).length > 0 ? parsedSearchParams : selectedSearchParams;

  setZIndex('root');

  const sOps = [];
  // if(process.env.REACT_APP_BUILD_TARGET === 'widget' && clSearchType != -1){
  //   if(clSearchType == 0 || clSearchType == -1){
  //     // All options please
  //     sOps.push(beachOption())
  //     sOps.push(cityOption())
  //     sOps.push(pakOption())

  //   }
  //   else if(clSearchType == 3){ 
  //     sOps.push(beachOption())
  //     sOps.push(cityOption())
  //   }
  // }
  // else{
  //   if(clSearchType == 0 || clSearchType == -1){
  //     // All options please
  //     sOps.push(beachOption())
  //     sOps.push(cityOption())
  //     sOps.push(pakOption())

  //   }
  //   else if(clSearchType == 3){ 
  //     sOps.push(beachOption())
  //     sOps.push(cityOption())
  //   }
  // }


  let buttonIco = search;
  const buttonIcoMob = search;

  if(configuration.button_icon == 'dark'){
    buttonIco = searchDark;
  }

  buttonIco = searchDark;

  if(!ready){

    const loader =<><div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      {LoaderSpinner()}</div></>;

    return loader;
  }

  let mTop = '10px';
  if(!showInnnerLabels){
    mTop = '35px';
  }


  let btnExClass = ''
  if(!showInnnerLabels){
    btnExClass = 'btnExt'
  }

  let buttonBorderColor = configuration.button_colour;
  let buttonTextColor = 'black';
  if(configuration.search_style != null){
    if(configuration.search_style.button_border != null){
      buttonBorderColor = configuration.search_style.button_border;
    }

    if(configuration.search_style.icon_dark != null){
      if(configuration.search_style.icon_dark == true){
        buttonIco = searchDark;
      }
      else{
        buttonIco = search;
      }
    }

    if(configuration.search_style.button_text_colour != null){
      buttonTextColor = configuration.search_style.button_text_colour;
    }

  }


  return (
    <>
      <div className='search-bar-container pak_refine'>
        <div className='collapsed-search-options pak_refine'>

          <AirportsSelect
            validationError={depAirVal}
            clickApplyHandler={handleDeparturePointParams}
            accommodationId={accommodationId}
            clickedSearchButtonItemName={searchItemType}
            selectedSearchParams={selectedSearchParams}
            showIcons={true}
            showInnnerLabels={showInnnerLabels}
            
          />


            <Datepicker
                validationError={dateVal}
                selectedParams={selectedSearchParams}
                clickedSearchButtonItemName={searchItemType}
                clickApplyHandler={handleDateParam}
                clickApplyHandlerMonth={handleDateMonthParam}
                searchItemType={searchItemType}
                showIcons={true}
                showInnnerLabels={showInnnerLabels}
            />
    

          <DurationSelect
            clickApplyHandler={handleDurationParam}
            clickedSearchButtonItemName={searchItemType}
            selectedSearchParams={selectedSearchParams}
            accommodationId={accommodationId}
            showIcons={true}
            showInnnerLabels={showInnnerLabels}
          />

          <RoomsSelect
            validationError={paxVal}
            clickApplyHandler={(value) => {
              if (value) {
                handleRoomsParam(value);
              }

              if (maxGuestsMessage) {
                setMaxGuestsMessage(null);
              }
            }}
            clickedSearchButtonItemName={searchItemType}
            accommodationId={accommodationId}
            selectedSearchParams={selectedSearchParams}
            showIcons={true}
            roomNumber={numberOfRooms}
            showInnnerLabels={showInnnerLabels}
          />



                    <>
                    <span className={'btn btnsearch ' + btnExClass}>
                    <Button label='SEARCH' borderColor={buttonBorderColor}  
                    backgroundColor={configuration.button_colour} isSearchIcon icon={buttonIco} 
                    color={buttonTextColor} primary onClick={handleOnSearchClick} iconBefore={true} />
                    </span>

                    <span className='btn--mob btnsearch'>
                    <Button label='SEARCH' borderColor={buttonBorderColor}  
                    backgroundColor={configuration.button_colour} isSearchIcon icon={buttonIco} 
                    color={buttonTextColor} primary onClick={handleOnSearchClick} iconBefore={true}  />
                    </span></>
      
        
        </div>
      </div>

      {maxGuestsMessage && <p className='search-bar-error'>{maxGuestsMessage}</p>}
    </>
  );
};

export default PackageRefine;
