import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { BaseLayout } from '../../components/BaseLayout';
import { ProgressBarDefault } from '../../components/ProgressBar/ProgressBarDefaults';
import { ProgressBarPackage } from '../../components/ProgressBar/ProgressBarPackage';
import { noop, uniqueId } from 'lodash';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';

import { PackageBasket } from '../../components/Basket/PackageBasket';
import { Basket } from '../../components/Basket/Basket';

import { SelectPayment } from '../../components/SelectPayment/SelectPayment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  getSessionSelections,
  getSessionPeople,
  paymentPayMonthly,
  journeyAdd
} from '../../hooks/useRequest';
import { CreditCard } from '../../components/CreditCard/CreditCard';

import PersonIcon from '../../components/assets/person.svg';
import PersonIconDefault from '../../components/assets/icons/default/08/Passengers.svg'

import VoucherIcon from '../../components/assets/voucher.svg';
import VoucherIconDefault from '../../components/assets/icons/default/08/Voucher.svg';

import { VoucherForm } from '../../components/VoucherForm/VoucherForm';

import './PaymentPage.scss';
import { PaymentInformation } from '../../components/PaymentInformation/PaymentInformation';

import { getBasketDP, getBasketPackage } from '../../services/basket';
import { accommodation as getAccommodation } from '../../services/acc';
import { sessionGetSearchParams } from '../../services/search';
import { adultCountFromRooms, childCountFromRooms, roomsFromPaxString } from '../../services/pax';

import { LoaderSpinner } from '../../components/Loader/jLoader';

import { Room, Flight, Transfer, Basket as BasketData, Accommodation, PackageRoom, Package as PackageHoliday, SearchSelectionRoom, Selections, Parking, AirportAccommodation, Lounge, DepositOption } from '../../hooks/types';
import { siteNavigate } from '../../services/nav';

interface IPaymentPage {
  onLogin?: () => void;
  openMenu?: () => void;
}

type Passenger = {
  title: string;
  first_name: string;
  second_name: string;
  date_of_birth: string;
  email_address: string;
  contact_telephone: string;
  address1: string;
  address2: string;
  address3?: string;
  address4?: string; 
  post_code: string;
};

export default function PaymentPage() {

  const [searchParams] = useSearchParams();
  const s = searchParams.get('session');
  const selectedSearchParams = sessionGetSearchParams();
  const aid = sessionStorage.getItem('selectedAccommodationID');

  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(true);
  const [leadPassenger, setLeadPassenger] = useState<Passenger | undefined>();
  const [otherPassengers, setOtherPassengers] = useState<Passenger[] | []>([]);
  
  const [paymentPlan, setPaymentPlan] = useState<any>(null);


  const [sessionId] = useState<string>(s || '');

  const [testees, setTestees] = useState<BasketData>();

  const [basket, setBasket] = useState<BasketData>();
  const [basketRooms, setBasketRooms] = useState<Room[]>();
  const [basketFlight, setBasketFlight] = useState<Flight>();

  const [basketTransfers, setBasketTransfers] = useState<Transfer | null>(null);
  const [basketParking, setBasketParking] = useState<Parking | null>(null);
  const [basketAirportAcc, setBasketAirpotAcc] = useState<AirportAccommodation | null>(null);
  const [basketLounge, setBasketLounge] = useState<Lounge | null>(null);

  const [basketPackageRooms, setBasketPackageRooms] = useState<PackageRoom[]>();
  const [basketPackage, setBasketPackage] = useState<PackageHoliday>();

  const [payToday, setPayToday] = useState<number>();

  const [paymentMethod, setPaymentMehtod] = useState<DepositOption | null>(null);

  const [accommodation, setAccommodation] = useState<Accommodation>();

  const [selections, setSelections] = useState<Selections>();
  const [selectedRooms, setSelectedRooms] = useState<SearchSelectionRoom[]>([]);

  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };


  const fNav = useNavigate();

  const loadBasket = async (tripType: number) => {
  
    if(tripType == 2 || tripType == 3){

      const basketPack = await getBasketPackage(userToken, sessionId);
      if(basketPack == null){
        return null;
      }

      setBasket(basketPack.basket);
      setBasketPackageRooms(basketPack.rooms);
      setBasketPackage(basketPack.package);
      setPayToday(basketPack.basket.price);

      updatePricePlan(sessionId);

      return basketPack.basket;
    }
    else{
      const basketDp =await getBasketDP(userToken, sessionId);
      if(basketDp == null){
        return null;
      }
      setBasket(basketDp.basket);
      setBasketRooms(basketDp.rooms);
      if(basketDp.flight != null){
        setBasketFlight(basketDp.flight);
      }
      setPayToday(basketDp.basket.price);
      updatePricePlan(sessionId);


      setBasketTransfers(basketDp.transfer);
      setBasketParking(basketDp.parking);
      setBasketAirpotAcc(basketDp.airport_accommodation);
      setBasketLounge(basketDp.lounge);

      return basketDp.basket;
    }
    
  };

  const goBackPax = () => {
    siteNavigate(configuration, `/passengers${location.search}`, fNav);
  }

  const updatePaymentMethod = (payToday: number, paymentMethod: DepositOption | null) => {
    setPayToday(payToday);
    setPaymentMehtod(paymentMethod);
  }

  const onVoucherUpdate = (basketData: BasketData) => {
    setBasket(basketData);
    updatePricePlan(sessionId);
  }

  const updatePricePlan  = (sessionId: any) => {
    paymentPayMonthly(userToken, 1, sessionId).then((pp) => {
      if(pp == null){
        return null;
      }
      if(pp.basket_payment_plan == null){
        return null;
      }
      if(pp.basket_payment_plan.result == null){
        return null;
      }
      setPaymentPlan(pp.basket_payment_plan.result)
    });
  }

  useEffect(() => {

    async function ini(){

      const selectionsResult = await getSessionSelections(userToken, sessionId);
      const sels = selectionsResult.session.result.selections;

      setSelections(sels);

      const selectedRooms = roomsFromPaxString(sels.rooms) as SearchSelectionRoom[];
      setSelectedRooms(selectedRooms);


      const aid = sels.accommodation_id;

      const res = await getSessionPeople(userToken, sessionId);

      if (res.session.result.basket.lead_person) {
        setLeadPassenger(res.session.result.basket.lead_person);
      }
      if (res.session.result.basket.people){
        const otherPeople = [];
        for (const p of res.session.result.basket.people) {
          if (p.id != res.session.result.basket.lead_person.id) {
            otherPeople.push(p);
          }
        }
        setOtherPassengers(otherPeople);
      }
      const basket = await loadBasket(sels.trip_type);

      let providerID = 4;
      if(basket != null){
        for(const x of basket.products){
          if(x.product_type.id == 0){
            providerID = x.provider_id;
          }
          else if(x.product_type.id == 5){
            providerID = x.provider_id;
          }
        }
      }

      const acc = await getAccommodation(userToken, Number(aid), providerID);
      if(acc != null){
        setAccommodation(acc);
      }

 

      const jse = sessionStorage.getItem('js4');
      if(jse == null && selectedSearchParams != null){

        const adults = adultCountFromRooms(selectedSearchParams.rooms);
        const children = childCountFromRooms(selectedSearchParams.rooms);

        journeyAdd(Number(aid), adults, children, selectedSearchParams.rooms.length, '', 'Payment', 4, sessionId || '', selectedSearchParams.searchType, userToken);
        sessionStorage.setItem('js4', '1');

      }

    }
    ini();

  }, []);

  
  // useEffect(() => {
  //   if (!sessionId) {
  //     return;
  //   }

  //   getAllRoomsFromBasket(userToken, sessionId).then((d: any) => {
  //     if (d.basket_rooms.result != null) {
  //       setBasketRooms(d.basket_rooms.result);
  //     }
  //   });
  // }, [sessionId]);

  // useEffect(() => {

  // }, [sessionId]);


  let progBar = null;
  if(selections?.trip_type == 2){
    ProgressBarPackage[0].isCompleted = true;
    ProgressBarPackage[1].isCompleted = true;
    ProgressBarPackage[2].isActive = true;
    progBar = ProgressBarPackage;
  }
  else{
    ProgressBarDefault.forEach((progress) => {
      progress.isActive = false;
      progress.isCompleted = false;
    });
  
    ProgressBarDefault[0].isCompleted = true;
    ProgressBarDefault[1].isCompleted = true;
    ProgressBarDefault[2].isCompleted = true;
    ProgressBarDefault[3].isActive = true;

    progBar = ProgressBarDefault;
  }

  const instalments: any[] = [];

  let depositAvail = false;
  let installmentsAvail = false;

  let paymentPlanId = null;
  if (paymentPlan && paymentPlan.plan.length > 0) {
    paymentPlan.plan.forEach((plan: any) => {
      instalments.push({ price: plan.amount, date: plan.date_due });
    });
    paymentPlanId = paymentPlan.id;
  }

  // if (basket.deposit_price != -1) {
  //   depositAvail = true;
  // }

  if (instalments.length > 0) {
    installmentsAvail = true;
  }


  // if(basket != null){
  //   vf = VoucherForm((basketResponse: any) => {
  //     if(basketResponse == null){
  //       return;
  //     }
  //     if(sessionId == null){
  //       return;
  //     }
  //     paymentPayMonthly(userToken, 1, sessionId, basketResponse.price_deposit_diff).then((d) => {
  //       setPaymentPlan(d.basket_payment_plan.result);
  //     })
  //   }, basket);
  // }

  


  let passIco = PersonIconDefault;
  let voucherIco = VoucherIconDefault;
  if(configuration.icon_set == 'blue'){
    passIco = PersonIcon;
    voucherIco = VoucherIcon;
  }


  let bask = null;

  if(basket == null || accommodation == null){
    return (
      <BaseLayout
        progressBar={progBar}
        isNavigationVisible={false}
        userToken={userToken}
        userConfiguration={configuration}>

  
          <div       style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '2rem',
        height: '600px'
      }}>
   {LoaderSpinner()}
          </div>
         

        
        
        </BaseLayout>)
  }

  let promoBox = null;




  promoBox =  <div style={{borderColor: configuration.jrny.border_colour}} className='select-payment-container container'>
  <h4 className='select-payment-title'>
    <img className='select-payment-icon' src={voucherIco} height='20' width='20' />
    <span className='select-payment-title-text'>Promo Code</span>
  </h4>

  <div style={{borderColor: configuration.jrny.border_colour}} className='select-payment-container-child select-payment-container-child--no-border container'>
    <VoucherForm basketData={basket} sessionId={sessionId} callback={(bd: BasketData) => onVoucherUpdate(bd)}></VoucherForm> 
  </div>
</div>

  

  if(selections?.trip_type == 2){
    
    if(basketPackage == null){
      return null;
    }
    if(basketPackageRooms == null){
      return null;
    }

    bask = <PackageBasket basketData={basket}  pricePlan={paymentPlan} selectedPackage={basketPackage} selectedRooms={basketPackageRooms} sessionId={sessionId} selectedAccommodation={accommodation} searchedRooms={selectedRooms}></PackageBasket>
  }
  else{
    bask = <Basket basketData={basket} pricePlan={paymentPlan} selectedFlight={basketFlight || null} selectedRooms={basketRooms || []} selectedTransfer={basketTransfers} selectedParking={basketParking} selectedAirportAccommodation={basketAirportAcc} selectedLounge={basketLounge}  sessionId={sessionId} selectedAccommodation={accommodation} searchedRooms={selectedRooms} />;
  }

  let custAddress = '';
  if(leadPassenger){
     const addressLines: string[] = [];

     addressLines.push(leadPassenger.address1);

     if(leadPassenger.address2 != null){
      if(leadPassenger.address2.trim() != ""){
        addressLines.push(leadPassenger.address2);
      }
     }

     if(leadPassenger.address3 != null){
      if(leadPassenger.address3.trim() != ""){
        addressLines.push(leadPassenger.address3);
      }
 
     }     
     if(leadPassenger.address4 != null){
      if(leadPassenger.address4.trim() != ""){
        addressLines.push(leadPassenger.address4);
      }
     }    

     addressLines.push(leadPassenger.post_code);

     custAddress = addressLines.join(', ');
  }


  return (
    <BaseLayout
      progressBar={progBar}
      onSearchButtonClick={() => setIsSearchBarOpen(!isSearchBarOpen)}
      isNavigationVisible={false}
      userToken={userToken}
      userConfiguration={configuration}
    >
      <div className='payment-page-container'>
        <div className='payment-page-col payment-page-col-basket payment-page-col-basket-mobile'>
          {bask}
        </div>

        <div className='payment-page-col'>
          <div style={{borderColor: configuration.jrny.border_colour}} className='select-payment-container select-payment-container--passengers container'>
            <h4 className='select-payment-title'>
              <img className='select-payment-icon' src={passIco} height='20' width='20' />
              <span className='select-payment-title-text'>Your Details</span>
            </h4>

            <div style={{borderColor: configuration.jrny.border_colour}} className='select-payment-container-child select-payment-passenger-details container'>
              <div className='select-payment-grid-50-50'>
                <div>
                  {leadPassenger && (
                    <div>
                      <h4 className='select-payment-title'>
                        <span className='select-payment-title-text'>Lead Passenger</span>
                      </h4>
                      <p>
                        {leadPassenger.title} {leadPassenger.first_name} {leadPassenger.second_name}
                        , {dayjs(leadPassenger.date_of_birth).format('DD/MM/YYYY')},{' '}
                        {leadPassenger.email_address.toLowerCase()}, {leadPassenger.contact_telephone}
                        <br></br>{custAddress}
                      </p>
                    </div>
                  )}
                  {otherPassengers.length > 0 && (
                    <div className='select-payment-other-passengers'>
                      <h4 className='select-payment-title'>
                        <span className='select-payment-title-text'>Other Passengers</span>
                      </h4>

                      <ul className='select-payment-passengers'>
                        {otherPassengers.map((passenger: Passenger) => (
                          <li key={uniqueId()}>
                            {passenger.title} {passenger.first_name} {passenger.second_name},{' '}
                            {dayjs(passenger.date_of_birth).format('DD/MM/YYYY')}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                <div className='select-payment-button-container'>
                  <button
                    style={{color:configuration.jrny.selected_button_text_colour, backgroundColor: configuration.jrny.selected_button_colour, fontFamily: 'GothamSSm', borderColor: configuration.jrny.selected_button_border_colour}}
                    type='button'
                    className='select-payment-button select-payment-button-active'
                    onClick={() => goBackPax()}
                  >
                    Change
                  </button>
                </div>
              </div>
            </div>
          </div>

      

          <PaymentInformation />

          <div className='select-payment-whitespace' />

          {promoBox}

          <div className='select-payment-whitespace' />

          <SelectPayment
            payment={{
              payInFull: true,
              instalments: installmentsAvail,
              deposit: depositAvail,
            }}
            instalments={instalments}
            basket={basket}
            onPriceChange={(newPrice, paymentMethod) => {
              updatePaymentMethod(newPrice, paymentMethod);
            }}
          />

          <CreditCard
            price={payToday}
            instalments={instalments}
            basket={basket}
            sessionId={sessionId || ''}
            paymentPlan={paymentPlanId || undefined}
            paymentMethod={paymentMethod}
            onPriceChange={(newPrice, paymentMethod) => {
              updatePaymentMethod(newPrice, paymentMethod);
            }}
          />
        </div>

        <div className='payment-page-col payment-page-col-basket'>
    
          {bask}
        
        </div>
      </div>
    </BaseLayout>
  );
};


