import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { uniqueId } from 'lodash';
import { getPaymentInformation } from '../../hooks/useRequest';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';

export function PaymentInformation() {
  const [wording, setWording] = useState<string[]>([]);

  const {
    RootStore: {
      sessionStore: { userSession },
      userToken,
      configuration
    },
  } = useStores() as { RootStore: RootStore };
  
  const [searchParams] = useSearchParams();

  useEffect(() => {


    const s = searchParams.get('session');

    async function getImportantPaymentInformation() {
      if (s) {
        const res = await getPaymentInformation(userToken, s);

        if (res.basket_important_information.result) {
          const items = res.basket_important_information.result;
          setWording(items);
        }
      }
    }

    getImportantPaymentInformation();
  }, []);


  if(wording.length == 0){
    return null;
  }

  let col = 'black';
  if(configuration.icon_set == 'blue'){
    col = '#01447b';
  }

  return (
    <>
    <div className='select-payment-whitespace' />
    <div style={{borderColor: configuration.jrny.border_colour}}  className='select-payment-container container'>
    <h4 className='select-payment-title select-payment-title--important-info'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth={1.5}
        stroke={col}
        style={{ width: 24, height: 24 }}
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z'
        />
      </svg>
      <span className='select-payment-title-text'>Important Information</span>
    </h4>

    <div style={{borderColor: configuration.jrny.border_colour}}  className='select-payment-container-child select-payment-container-child--no-border container'>
      <div>
        {wording.map((item) => (
          <p key={uniqueId()}>{item}</p>
        ))}
      </div>
    </div>
  </div>
  </>
  );
}
