import React, { FunctionComponent } from 'react'

import { Splide, SplideSlide } from '@splidejs/react-splide';
import { uniqueId } from 'lodash';

import AtolIcon from '../assets/atol.svg';
import AtbaIcon from '../assets/atba.svg';
import CashIcon from '../assets/cash.svg';

import atolIconDefault from '../assets/icons/default/04/Atol.svg';
import atbaIconDefault from '../assets/icons/default/04/abta-1.svg';
import cashIconDefault from '../assets/icons/default/04/noun-pound-1634070-222222.svg';

import PaymentCard from '../assets/payment-card.svg';
import paymentCardDefault from '../assets/icons/default/04/payment-card.svg';

import Logo from '../assets/logo.svg';

import './PackageSelectLoader.scss';
import '../Loader/Loader.scss';

function LoaderSpinner(buttoncolour='red'){


  
  return (
    <div style={{borderColor: buttoncolour + ' transparent transparent transparent'}} className='loader-ring'>
      <div style={{borderColor: buttoncolour + ' transparent transparent transparent'}} />
      <div style={{borderColor: buttoncolour + ' transparent transparent transparent'}} />
      <div style={{borderColor: buttoncolour + ' transparent transparent transparent'}}/>
      <div style={{borderColor: buttoncolour +  ' transparent transparent transparent'}} />
    </div>
  );
};

interface LoaderProps {
  configuration?: any;
}

export const PackageSelectLoader: FunctionComponent<LoaderProps> = ({configuration}: LoaderProps) => {


  let logoSrc = 'https://booking.haystravel.co.uk/static/media/hays_logo.svg'
  if(configuration != null){
    logoSrc = configuration.logo_file;
  }

  let atolIco = atolIconDefault;
  let abtaIco = atbaIconDefault;
  let cashIco = cashIconDefault;
  let cardIco = paymentCardDefault;

  if(configuration.icon_set == 'blue'){
    atolIco = AtolIcon;
    abtaIco = AtbaIcon;
    cashIco = CashIcon;
    cardIco = PaymentCard;
  }

  let bs = configuration.button_colour
  let message = <p style={{color: configuration.text_colour}} className='package-select-loader-modal__title'>Deposits from only &pound;60pp</p>
  let loadBack = '#fff'
  // if(configuration.spec == '6yk1y'){;
  //   bs = '#01447b';
  //   message = <p style={{color: configuration.text_colour}} className='package-select-loader-modal__title'>Zero deposit until 31st January</p>;
  // }

  if(configuration.header_icon_colour == "white"){
    loadBack = configuration.head_colour;
  }




  const spinner = LoaderSpinner(bs);
  return (
    <div style={{background: loadBack}} className='package-select-loader-overlay'>
      <img src={logoSrc} height={50} className='package-select-loader-logo' />

      <div className='package-select-loader-modal'>
        <Splide
          key={uniqueId()}
          aria-label='Unique Selling Points for Packages'
          options={{
            cover: true,
            perMove: 1,
            pagination: false,
            gap: '1rem',
            arrows: false,
            drag: false,
            autoplay: true,
            interval: 6000,
            infinite: true,
            pauseOnFocus: false,
            pauseOnHover: false,
            rewind: true,
            type: 'fade',
          }}
        >
          <SplideSlide>
            <div>
              {message}
              <img width={80} src={cashIco} />
            </div>
          </SplideSlide>

          {/* <SplideSlide>
            <div>
              <p className='package-select-loader-modal__title'>Interest free monthly payments</p>
              <img width={80} src={cardIco} />
            </div>
          </SplideSlide> */}
        </Splide>

        <div className='package-select-loader-modal__spinner'>
          {spinner}
        </div>

        <p style={{color: configuration.text_colour}}>Please wait, this may take up to 45 seconds</p>

        <div className='package-select-loader-modal__logos'>
          <img width={120} src={abtaIco} />
          <img width={50} src={atolIco} />
        </div>
      </div>
    </div>
  );
}
