import { FC, useState, useEffect } from 'react';
// import { ISelectPayment, IDeposit, IFullPayment, IMonthlyPayments } from './Interfaces';
import './SelectPayment.scss';

import CreditCardIcon from '../assets/creditcard.svg';
import CreditCardIconDefault from '../assets/icons/default/08/ops.svg';

import { Button } from '../Button';
import dayjs from 'dayjs';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';
import { Basket, DepositOption } from '../../hooks/types'

interface MonthlyPaymentProps {
  depositOption: DepositOption
  instalments: {
    price: number;
    date: Date;
  }[];
  selected: boolean;
  onClick: () => void;
  config: any;
}

const MonthlyPayments: FC<MonthlyPaymentProps> = ({ depositOption, instalments, selected, onClick, config }) => {

  let s = {borderColor: config.jrny.border_colour};
  if(selected){
    s = {borderColor: config.button_colour}
  }

  return (
    <div
      style={s}
      className={`select-payment-container-child ${
        selected ? 'select-payment-container-child-active' : ''
      }`}
    >
      <h4 className='select-payment-title'>
        <span className='select-payment-title-text'>{depositOption.description}</span>
      </h4>
      <div className='select-payment-grid-50-50'>
        <div>
          <div className='select-payment-breakdown select-payment-breakdown-active'>
            <div style={{backgroundColor: config.jrny.payment_option_colour}} className='select-payment-circle select-payment-circle-active'>
              {instalments.length !== 0 && instalments.length !== 1 && (
                <div  style={{backgroundColor: config.jrny.payment_option_colour}} className='select-payment-circle-line select-payment-circle-line-active' />
              )}
            </div>
            <div className='select-payment-breakdown-title'>
              <b>Pay today</b>
            </div>

            <div className='select-payment-breakdown-price'>£{depositOption.basket_price.toFixed(2)}</div>
          </div>

          {instalments.map((instalment, index) => {
            return (
              <div className='select-payment-breakdown' key={'instalment' + index}>
                <div style={{backgroundColor: config.jrny.payment_option_future_colour}} className='select-payment-circle'>
                  {instalments.length !== index + 1 && (
                    <div className='select-payment-circle-line select-payment-circle-line' />
                  )}
                </div>
                <div className='select-payment-breakdown-title select-payment-breakdown-title-with-date'>
                  <b>
                    {index + 1 === instalments.length ? 'Final Payment' : `Instalment ${index + 1}`}
                  </b>
                  <div className='select-payment-breakdown-date'>
                    {dayjs(instalment.date).format('DD/MM/YY')}
                  </div>
                </div>
                <div className='select-payment-breakdown-price'>
                  {' '}
                  £{instalment.price.toFixed(2)}{' '}
                </div>
              </div>
            );
          })}
        </div>
        <div className='select-payment-button-container'>
          <Button
            isSelected={selected}
            onClick={onClick}
            overrideClassNames={`select-payment-button ${
              selected ? 'select-payment-button-active' : ''
            }`}
            label={`${selected ? 'Selected' : 'Select'}`}
            color={config.button_text_secondary_colour}
          />
        </div>
      </div>
    </div>
  );
};

interface DepositPaymentProps {
  depositOption: DepositOption
  onClick?: () => void;
  selected: boolean;
  config: any;
}

const DepositPayment: FC<DepositPaymentProps> = ({ depositOption, selected, onClick, config }) => {

  let s = {borderColor: config.jrny.border_colour};
  if(selected){
    s = {borderColor: config.button_colour}
  }

  return (
    <div
      style={s}
      className={`select-payment-container-child ${
        selected ? 'select-payment-container-child-active' : ''
      }`}
    >
      <h4 className='select-payment-title'>
        <span className='select-payment-title-text'>{depositOption.description}</span>
      </h4>
      <div className='select-payment-grid-50-50'>
        <div>
          <div className='select-payment-breakdown'>
            <div style={{backgroundColor: config.jrny.payment_option_colour}} className='select-payment-circle select-payment-circle-active'>
              <div style={{backgroundColor: config.jrny.payment_option_colour}} className='select-payment-circle-line select-payment-circle-line-active' />
            </div>

            <div className='select-payment-breakdown-title'>
              <b>Pay today</b>
            </div>

            <div className='select-payment-breakdown-price'>£{depositOption.basket_price.toFixed(2)}</div>
          </div>
          <div className='select-payment-breakdown'>
            <div style={{backgroundColor: config.jrny.payment_option_future_colour}} className='select-payment-circle' />
            <div className='select-payment-breakdown-title'>
              <b>Final payment</b>
                <div className='select-payment-breakdown-date'>
                  {dayjs(depositOption.balance_due).format('DD/MM/YY')}
                </div>
            </div>

            <div className='select-payment-breakdown-price'> £{depositOption.outstanding_balance.toFixed(2)} </div>
          </div>
        </div>
        <div>
          <div className='select-payment-button-container'>
            <Button
              isSelected={selected}
              onClick={onClick}
              overrideClassNames={`select-payment-button ${
                selected ? 'select-payment-button-active' : ''
              }`}
              label={`${selected ? 'Selected' : 'Select'}`}
              color={config.button_text_secondary_colour}
            />
          </div>
        </div>
      </div>
    </div>
  );
};




interface FullPaymentProps {
  total: number;
  onClick?: () => void;
  selected: boolean;
  config: any;
}

const FullPayment: FC<FullPaymentProps> = ({ total, selected, onClick, config }) => {

  let s = {borderColor: config.jrny.border_colour};
  if(selected){
    s = {borderColor: config.button_colour}
  }

  return (
    <div id='selectPaymentFormFullOp'    style={s}
      className={`select-payment-container-child ${
        selected ? 'select-payment-container-child-active' : ''
      }`}
    >
      <h4 className='select-payment-title'>
        <span className='select-payment-title-text'>Pay in full</span>
      </h4>
      <div className=' select-payment-grid-50-50'>
        <div className='select-payment-breakdown'>
          <div style={{backgroundColor: config.jrny.payment_option_colour}} className='select-payment-circle select-payment-circle-active'></div>
          <div className='select-payment-breakdown-title'>
            <b>Full payment</b>
          </div>

          <div className='select-payment-breakdown-price'>£{total.toFixed(2)}</div>
        </div>
        <div className='select-payment-button-container'>
          <Button
            isSelected={selected}
            onClick={onClick}
            overrideClassNames={`select-payment-button ${
              selected ? 'select-payment-button-active' : ''
            }`}
            label={`${selected ? 'Selected' : 'Select'}`}
            color={config.button_text_secondary_colour}
          />
        </div>
      </div>
    </div>
  );
};

interface SelectPaymentProps {
  payment: {
    instalments: boolean;
    payInFull: boolean;
    deposit: boolean;
  };
  basket: Basket;
  instalments: {
    price: number;
    date: Date;
  }[];
  onPriceChange?: (price: number, paymentMethod: DepositOption | null) => void;
}

  const SelectPayment: FC<SelectPaymentProps> = ({ payment, basket, instalments, onPriceChange}) => {

  const [selectedPayment, setSelectedPayment] = useState<DepositOption | null>(null);

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };


  // useEffect(() => {
  //   setSelectedPayment(defPayMethod)
  // }, [defPayMethod])

  const deposit = basket.deposit_price;

  const setSelected = (paymentMethod: DepositOption | null, price?: number) => {
    setSelectedPayment(paymentMethod);
    if (onPriceChange && price != null){
      onPriceChange(price, paymentMethod);
    } 
  };

  let cardIco = CreditCardIconDefault;
  if(configuration.icon_set == 'blue'){
    cardIco = CreditCardIcon;
  }


  const depositOps: any[]= [];

  for(const x of basket.deposit_options){

    if(x.force_payment_method == 'dd' && instalments){
        depositOps.push(<MonthlyPayments
        depositOption={x}
        selected={selectedPayment?.id === x.id}
        onClick={() => {
          setSelected(x, x.basket_price);
        }}
        instalments={instalments || []}
        config={configuration}
      />)
      continue
    }

    depositOps.push(<DepositPayment
      depositOption={x}
      onClick={() => {
        setSelected(x, deposit);
      }}
      selected={selectedPayment?.id === x.id}
      config={configuration}
    />)

  }




  return (
    <div>
      <div id='selectPaymentForm' style={{borderColor: configuration.jrny.border_colour}} className='select-payment-container'>
        <h4 className='select-payment-title'>
          <img className='select-payment-icon' src={cardIco} height='20' width='20' />
          <span className='select-payment-title-text'>Payment Options</span>
        </h4>

        {depositOps}

        <FullPayment
          onClick={() => {
            setSelected(null, basket.price);
          }}
          selected={selectedPayment == null}
          total={basket.price || 0}
          config={configuration}
        />
      </div>
      <div className='select-payment-whitespace' />
    </div>
  );
};

export { SelectPayment };
