import React, { FunctionComponent } from 'react';
import dayjs from 'dayjs';

import { Button } from '../Button/Button';
import { Option } from '../Autocomplete';
import { PaymentOptions } from '../PaymentOptions';
import FlightDetails from './FlightDetails/FlightDetails';
import { PanelLoader } from '../PanelLoader/PanelLoader';

import luggage from '../assets/suitcase.svg';
import luggageDefault from '../assets/icons/default/03/Handluggage.svg';

import bag from '../assets/bag.svg';
import bagDefault from '../assets/icons/default/03/Bag.svg';

import bus from '../assets/bus.svg';
import busDefault from '../assets/icons/default/03/Transfer.svg';

import atol from '../assets/atol.svg';
import atolDefault from '../assets/icons/default/03/Atol.svg';

import './HolidaySummary.scss';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

import {Offer } from '../../hooks/types';

import { SearchSelectionsFromUrlParams } from '../../services/search';

import {adultCountFromRooms, childCountFromRooms, roomCount} from '../../services/pax';

interface HolidaySummaryProps {
  offer: Offer;
  selectedSearchParams: URLSearchParams;
  headerTitle: string;
  isFetchingData?: boolean;
  isPackage?: boolean;
  onChildrenSelect?: () => void;
  onHolidaySelect: () => void;
  pricePlan: any | null
}

const HolidaySummary: FunctionComponent<HolidaySummaryProps> = ({
  offer,
  headerTitle,
  isFetchingData,
  isPackage = false,
  pricePlan = null,
  selectedSearchParams,
  onHolidaySelect,
}: HolidaySummaryProps) => {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };
  
  const generateTotalHolidayTime = () => {
    const startDate = offer.flights[0].arrival;
    const duration = offer.duration;
    const formattedStartDate = dayjs(startDate).format('ddd DD MMM YYYY');
    const formattedEndDate = dayjs(startDate).add(duration, 'days').format('ddd DD MMM YYYY');
    const nightLabel = duration > 1 ? 'nights' : 'night';
    return `${formattedStartDate} - ${formattedEndDate} (${duration} ${nightLabel})`;
  };

  let luggageIco = luggageDefault;
  let bagIco = bagDefault;
  let busIco = busDefault;
  let atolIco = atolDefault;

  if(configuration.icon_set == 'blue'){
    luggageIco = luggage;
    bagIco = bag;
    busIco = bus;
    atolIco = atol;
  }

  let pricePP = offer.price_per_person.toFixed(0);
  let tPrice = offer.price.toFixed(0);
  

  const parsedSelections = SearchSelectionsFromUrlParams(selectedSearchParams);

  const adultCount = adultCountFromRooms(parsedSelections.rooms);
  const childCount = childCountFromRooms(parsedSelections.rooms);
  const travellerCount = adultCount + childCount;
  const numberOfRooms = roomCount(parsedSelections.rooms);

  let strPax = [];
  if(adultCount == 1){
    strPax.push(adultCount + ' adult')
  }
  else{
    strPax.push(adultCount + ' adults')
  }

  if(childCount > 0){
    if(childCount == 1){
      strPax.push(childCount + ' child')
    }
    else{
      strPax.push(childCount + ' children')
    }
 
  }

  return (
    <>
      <div className='holiday-summary-wrapper'>
        <div style={{backgroundColor: configuration.container_colour}} className='holiday-summary--header'>
          <span className='holiday-summary--header__span'>{headerTitle}</span>
        </div>
        {/* <div className='summary-overlay'>OverlayTestees</div> */}
        <div style={{borderColor: configuration.container_colour}} className='holiday-summary-body'>
          {isFetchingData ? (
            <PanelLoader title='Loading Offer' />
          ) : (
            <>

             

              <div className='top-section'>
                <div className='top-section--title'>{offer.accommodation.name}</div>
                <div className='top-section--subtitle'>
                  <p>{generateTotalHolidayTime()}</p>
                  <p>{offer.rooms[0].board_description}</p>
                  <p>{`${strPax.join(' ')}`}</p>
                </div>
              </div>

              <div className='middle-section'>
                <FlightDetails
                  flightType={'Outbound'}
                  airline={offer.flights[0].airline}
                  flightNumber={offer.flights[0].number}
                  departureTime={offer.flights[0].departing}
                  departurePlace={offer.flights[0].departure_airport.name}
                  arrivalPlace={offer.flights[0].arrival_airport.name}
                  arrivalTime={offer.flights[0].arrival}
                  outBound={true}
                  config={configuration}
                />
                <FlightDetails
                  flightType={'Inbound'}
                  airline={offer.flights[0].airline}
                  flightNumber={offer.flights[0].return_number}
                  departureTime={offer.flights[0].return_departing}
                  departurePlace={offer.flights[0].arrival_airport.name}
                  arrivalPlace={offer.flights[0].departure_airport.name}
                  arrivalTime={offer.flights[0].return_arrival}
                  outBound={false}
                  config={configuration}
                />
              </div>

              <div className='bottom-section'>
                {isPackage && (
                  <div className='bottom-section__extras'>
                    <div className='bottom-section__extra'>
                      <img src={bagIco} alt='' />
                      <div className='bottom-section__extra-item'>
                        <span>
                          {travellerCount
                            ? `(${travellerCount}) 22kg Bag Allowance`
                            : '22kg Bag Allowance'}
                        </span>
                      </div>
                    </div>

                    <div className='bottom-section__extra'>
                      <img src={luggageIco} alt='' />
                      <div className='bottom-section__extra-item'>
                        <span>
                          {travellerCount
                            ? `(${travellerCount}) 10kg Hand Baggage`
                            : '10kg Hand Baggage'}
                        </span>
                      </div>
                    </div>

                    <div className='bottom-section__extra'>
                      <img src={busIco} alt='' />
                      <div className='bottom-section__extra-item'>
                        <span>Coach Transfers</span>
                      </div>
                    </div>

                    <div className='bottom-section__extra'>
                      <img src={atolIco} alt='' />
                      <div className='bottom-section__extra-item'>
                        <span>ATOL Protection</span>
                      </div>
                    </div>
                  </div>
                )}

                <div className='bottom-section--price-info'>
                  <div className={'price-per-person'}>
                    <span className={'title'}>Price per person</span>
                    <span style={{color: configuration.price_colour}} className={'price'}>£{pricePP}</span>
                  </div>
                  <div className={'total-price'}>
                    <span className={'title'}>Total price</span>
                    <span style={{color: configuration.price_colour}} className={'price'}>£{tPrice}</span>
                  </div>
                </div>

                <div className='bottom-section--payment-options'>
                   <PaymentOptions depositOptions={offer.deposit_options} pricePlan={pricePlan} live={false} />
                </div>

                <Button label={'Select Holiday'} primary={true} borderColor={configuration.button_colour} backgroundColor={configuration.button_colour} onClick={() => onHolidaySelect()} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default HolidaySummary;
