import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Select, { StylesConfig } from 'react-select';

import './CreditCard.scss';
import './DirectDebit.scss';

import '../SelectPayment/SelectPayment.scss';

import logo from '../../direct-debit-logo.svg';

import lockIcon from '../assets/padlock-check.svg';
import lockIconDefault from '../assets/icons/default/08/lock.svg';

import CVVIcon from '../assets/cvv.svg';
import CVVIconDefault from '../assets/icons/default/08/CVV.svg';

import Mastercard from '../assets/mastercard.svg.png';
import Visa from '../assets/visa.svg.png';
import Secureshield from '../assets/secureshield.svg';

import Atba from '../assets/atba.svg';
import Atol from '../assets/atol.svg';

import atolIconDefault from '../assets/icons/default/04/Atol.svg';
import atbaIconDefault from '../assets/icons/default/04/abta-1.svg';

import Tick from '../assets/tick.svg';
import { Button } from '../Button';

import { CustomDropdownIndicator } from '../Room/CustomDropdownIndicator';
import { Option } from '../Autocomplete';
import { LoaderSpinner } from '../Loader/Loader';
import {
  makeBooking,
  paymentStepOne,
  paymentStepTwo,
  productCheckAll,
  productToleranceCheck,
  getLeadPaxAddress,
  siteConfiguration,
  paymentPayMonthly,
} from '../../hooks/useRequest';
import { IFrame } from './Iframe';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';
import { CreditCardTerms } from './CreditCardTerms';

import { Basket, Agent, PaymentResult, DepositOption } from '../../hooks/types';
import { getCookie } from '../../services/session';
import { siteNavigate } from '../../services/nav';


// get all year dates
const yearOptions: Option[] = [];

for (let i = 0; i < 50; i++) {
  const date = dayjs().add(i, 'years');
  yearOptions.push({ value: date.year(), label: date.year() });
}

const datesOptions: Option[] = [];
[
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
].forEach((month, index) => {
  datesOptions.push({ value: (index + 1), label: month });
});


const dayOptions: Option[] = [];

for(let i=1; i < 29; i++){

  let lbl = i + 'th';
  if(i == 1){
    lbl = i + 'st';
  }
  else if(i == 2){
    lbl = i + 'nd';
  }
  else if(i == 3){
    lbl = i + 'rd';
  }

  if(i > 19){
    const x = i.toString();
    const lastdig = Number(x[x.length - 1]);
    if(lastdig == 1){
      lbl = x + 'st';
    }
    else if(lastdig == 2){
      lbl = x + 'nd';
    }
    else if(lastdig == 3){
      lbl = x + 'rd';
    }
    else{
      lbl = x + 'th';
    }

  }
  dayOptions.push({value : i, label: lbl})
}


interface CreditCardProps {
  price?: number;
  sessionId: string;
  basket: Basket;
  onBooking?: (bookingId: number) => void;
  instalments?: {
    price: number;
    date: Date;
  }[];
  paymentPlan?: number | undefined;
  paymentMethod: DepositOption | null,
  onPriceChange?: (price: number, paymentMethod: DepositOption | null) => void;
  onDDDayChange? : (day: number) => void
}

export const CreditCard: FC<CreditCardProps> = ({
  price,
  sessionId,
  basket,
  onBooking,
  instalments,
  paymentPlan,
  paymentMethod,
  onPriceChange,
}) => {
  const paymentForm = useRef<HTMLFormElement>(null);
  const gatewayForm = useRef<HTMLFormElement>(null);
  const fNav = useNavigate();
  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const [acceptsTerms, setAcceptsTerms] = useState(false);
  const [searchParams] = useSearchParams();

  let defaultFail = false;
  const f = searchParams.get('f');
  if(f != null){
    if(f == '1'){
      defaultFail = true;
    }
  }

  const [showErrorMessage, setShowErrorMessage] = useState(defaultFail);
  const [formErrors, setFormErrors] = useState<Record<string, boolean>>({});
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [completedIndicator, setCompletedIndicator] = useState(false);
  const [bookingId, setBookingId] = useState(0);
  const [is3DSecure, set3DSecure] = useState(false);
  const [paymentResult, setPaymentResult] = useState<PaymentResult>();


  const [ddA, setDDA] = useState(false)
  const [ddB, setDDB] = useState(false)
  const [ddC, setDDC] = useState(false)
  const [ddD, setDDD] = useState(false)

  const [ddVal, setDDVal] = useState<any>(null);
  const [ddFormCompleted, setDDFormCompleted] = useState(false);
  const [ddFormYesVal, setDDYesFormConfirm] = useState(false);
  const [ddFormNoVal, setDDNoFormConfirm] = useState(false);

  const [ddFormVal, setDDFormVal] = useState<any>(null);
  const [ddFormConfirmVal, setDDFormConfirmVal] = useState<any>(null);


  const [ddAccountHolderName, setDDAccountHolderName] = useState('');
  const [ddBankName, setDDBankName] = useState('');
  const [ddAccountNumnber, setDDAccountNumber] = useState('');
  const [ddAccountNumnberMessage, setDDAccountNumberMessage] = useState('');
  const [ddSortCode, setDDSortCode] = useState('');
  const [ddSortCodeMessage, setDDSortCodeMessage] = useState('');

  const [ddBankAddress, setDDBankAddress] = useState('na');
  const [ddBankPostCode, setDDBankPostCode] = useState('na');
  const [ddPayDay, setDDPayDay] = useState<Option>({value: 1, label: '1st'});

  const [cardNumber, setCardNumber] = useState('');
  const [expiryMonth, setExpiryMonth] = useState<any>(null);
  const [expiryYear, setExpiryYear] = useState<any>(null);
  const [cardName, setCardName] = useState('');
  const [cardCVV, setCardCVV] = useState('');

  const [ddShowPaymentForm, setShowPaymentForm] = useState(false);

  const [payFormVal, setPayFormVal] = useState<any>(null);

  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const [ddInstallments, setDDInstallments] = useState(instalments);

  const [schedUpdating, setSchedUpdating] = useState<boolean>(false);

  const [selectedDay, setSelectedDDDay] = useState(null);

  // const [values, setValues] = useState<Record<string, string>>({
  //   expiryMonth: '',
  //   expiryYear: '',
  //   cardName: '',
  //   cvvNumber: '',
  //   cardNumber: '',
  //   ddAccName: '',
  //   ddAccAddress: '',
  //   ddBankName: '',
  //   ddAccNumber: '',
  //   ddSortCode: '',
  //   ddBankAddress: '...',
  //   ddBankPostcode: '...',
  // });

  const [PGWJWT, setPGWJWT] = useState('');
  const [paymentSession, setPaymentSession] = useState('');

  // const paymentError = (x: boolean) => {
  //   setShowErrorMessage(x);

  //   if (x) {
  //     // payment has errord now
  //     (document.getElementById('cvvNumber') as HTMLInputElement).value = '';
  //   }
  // };


  const StylesSelect: StylesConfig = {
    container: (base: any) => ({
      ...base,
      marginTop: '10px',
      color: configuration.text_colour,
    }),
    control: (base: any) => ({
      ...base,
      borderColor: configuration.border_colour_2,
    }),
    singleValue: (base: any) => ({
      ...base,
      color: configuration.text_colour,
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      marginTop: 0,
      marginBottom: 0,
    }),
    menu: (base: any) => ({
      ...base,
      borderColor: configuration.border_colour_2,
      backgroundColor: 'white',
      position: 'absolute',
      color: configuration.text_colour,
    }),
  };

  // const updateErrorsById = (id: string, _values: any) => {
  //   formErrors[id] = _values[id].length === 0 ? true : false;
  //   setFormErrors({ ...formErrors });
  // };

  // const updateErrors = () => {
  //   const errors: Record<string, boolean> = {};
  //   Object.keys(values).forEach((value) => {
  //     if (values[value]?.length === 0) {
  //       errors[value] = true;
  //     } else {
  //       errors[value] = false;
  //     }
  //   });
  //   setFormErrors({ ...errors });
  // };

  // const updateValue = (e: any, id: string) => {
  //   values[id] = e.value;
  //   setValues({ ...values });
  //   updateErrorsById(id, values);
  // };

  // const updateInputValue = (e: any) => {
  //   values[e.currentTarget.id] = e.currentTarget.value;
  //   setValues({ ...values });
  //   updateErrorsById(e.currentTarget.id, values);
  // };
  useEffect(() => {

    if(showErrorMessage){
      const es =document.getElementById('errorSec');
      if(es != null){
        es.scrollIntoView();
      }

    }


  }, [showErrorMessage]);

  useEffect(() => {
    const sessionId = searchParams.get('session');
    if (sessionId == null) {
      return;
    }



    const strCustomerAddress = getLeadPaxAddress(userToken, sessionId).then((sessionResponse) => {
      let sessionResult = null;
      if (sessionResponse.session != null) {
        sessionResult = sessionResponse.session.result;
      } else {
        return;
      }

      const add = [];
      if (sessionResult.basket.lead_person.address1 != '') {
        add.push(sessionResult.basket.lead_person.address1);
      }
      if (sessionResult.basket.lead_person.address2 != '') {
        add.push(sessionResult.basket.lead_person.address2);
      }
      if (sessionResult.basket.lead_person.address3 != '') {
        add.push(sessionResult.basket.lead_person.address3);
      }
      if (sessionResult.basket.lead_person.address4 != '') {
        add.push(sessionResult.basket.lead_person.address4);
      }
      if (sessionResult.basket.lead_person.post_code != '') {
        add.push(sessionResult.basket.lead_person.post_code);
      }
    });
  }, []);

  useEffect(() => {

    if(ddInstallments?.length == 0){
      setDDInstallments(instalments);
    }


  }, [instalments])
  useEffect(() => {
    if (PGWJWT && paymentSession && is3DSecure) {
      setTimeout(() => {
        if (gatewayForm.current) {
          gatewayForm.current.submit();
        }
      }, 500);
    }
  }, [PGWJWT, paymentSession]);
  // Determine what payment summary to show

  useEffect(() => {

    if(ddShowPaymentForm){

      window.location.href = '#payForm';
    }
    
  }, [ddShowPaymentForm]);

  const showSuccess = (bookingId: number) => {
    const sessionId = searchParams.get('session');
    setCompletedIndicator(true);
    setTimeout(() => {
      siteNavigate(configuration, `/booking-confirmed/?session=${sessionId}&bid=${bookingId}`, fNav);
    }, 3000);
  };

  const bookProds = async (_bookingId: number) => {

    const bookComs = await siteConfiguration('12', userToken)
    if(bookComs == 0){
      showSuccess(_bookingId);
    }
    // const prodResults = [];
    // // const booking = await getBookingDetails(sessionId, _bookingId);
    // // for(const x of booking.session_from_booking.result.basket.products){
    // //   const pd = await bookProduct(sessionId, _bookingId, x.id, x.product_type.id)
    // //   prodResults.push(pd)
    // // }
    // const bookingProducts = await bookAllProducts(userToken, sessionId, _bookingId);
    // // based on success of prods may want to show a different success/pending message.
    // // for now just go to success. Hand off can take place regardless of component sucess.
    // await handOffBooking(userToken, sessionId, _bookingId);
    // if (paymentMethod === 1) {
    //   await setUpDirectDebit(_bookingId);
    // }

    let ryanAirSession = "";
    for(const p of basket.products){
      if(p.product_type.id == 1){
        if(p.supplier_session != ""){
          ryanAirSession = p.supplier_session;
        }

      }
    }
    

    if(ryanAirSession != ""){
      siteNavigate(configuration, `/ryr-confirm/?session=${sessionId}&bid=${_bookingId}&ryr_session=${ryanAirSession}`, fNav);
    }
    else{
      //navigate to book page (will actually book the components here)
      siteNavigate(configuration, `/booking/?session=${sessionId}&bid=${_bookingId}`, fNav);
    }

  };



  const setDirectA = (value: any) => {
    if(!value){
      return
    }
    setDDA(value);
    setDDB(false);
  }  
  
  const setDirectB = (value: any) => {
    if(!value){
      return
    }
    setDDB(value);
    setDDA(false);
  }  
  
  const setDirectC = (value: any) => {
    if(!value){
      return
    }
    setDDC(value);
    setDDD(false);
  }  
  
  const setDirectD = (value: any) => {
    if(!value){
      return
    }
    setDDD(value);
    setDDC(false);
  }

  const continueDD = () => {

    if(!ddA || !ddC){
      setDDVal(false);
      setDDFormVal(null);
      return
    }
    setDDVal(true);
  }
  
  const cancelDDForm = () => {

    if(ddFormCompleted){
      setDDFormCompleted(false);
    }
    else{
      setDDVal(null);
      setDDFormVal(null);
      setDDFormCompleted(false);
    }
  }

  const validateDDForm = () => {

    const sc = ddSortCode.replaceAll('-', '');

    if(ddAccountHolderName == null || ddAccountHolderName.trim() == ''){
      setDDFormVal(false);
      return false
    }
    if(ddBankName == null || ddBankName.trim() == ''){
      setDDFormVal(false);
      return false
    }
    if(ddAccountNumnber == null || ddAccountNumnber.trim() == ''){
      setDDFormVal(false);
      return false
    }

    if(ddAccountNumnber.trim().length != 8){
      setDDFormVal(false);
      return false
    }

    if(sc == null || sc.trim() == ''){
      setDDFormVal(false);
      return false
    }      

    if(sc.trim().length != 6){
      setDDFormVal(false);
      return false
    }      

    if(ddBankAddress == null || ddBankAddress.trim() == ''){
      setDDFormVal(false);
      return false
    }
    if(ddBankPostCode == null || ddBankPostCode.trim() == ''){
      setDDFormVal(false);
      return false
    }

    return true;
  }


  const continueDDForm = () => {

    if(ddFormCompleted){

      if(!ddFormYesVal){
          setDDFormConfirmVal(false);
        }
        else{
          setDDFormConfirmVal(true);
        }

      if(ddFormNoVal){
        setDDFormVal(false);
        setShowPaymentForm(false);
        return;
      }

     

      // Validate the DD Form (jsut double check)
      const v = validateDDForm()
      if(!v){
        setShowPaymentForm(false);
        return;
      }
      setShowPaymentForm(true);
    }
    else{

      const v = validateDDForm()
      setDDFormCompleted(v);
    }
 
  }

  const backtoDDForm = () => {
    setShowPaymentForm(false);

  }

  const setDDFYes = (value: any) => {
    if(!value){
      return
    }
    setDDYesFormConfirm(value);
    setDDNoFormConfirm(false);
  }  
  
  const setDDFNo = (value: any) => {
    if(!value){
      return
    }
    setDDNoFormConfirm(value);
    setDDYesFormConfirm(false);
    setShowPaymentForm(false); 
  }


  const validateAccNumber = (value: string) => {
    let dispMsg = false;
    let nv = ''
    for(const x of value){

      if(!isNaN(Number(x))){
        nv += x;
      }else{
        dispMsg = true;
      }
    }
    setDDAccountNumber(nv)

    if(dispMsg){
      setDDAccountNumberMessage('Please only enter numbers');
    }
    else{
      setDDAccountNumberMessage('');
    }

  }

  const validateSortCode = (value: string) => {

    let dispMsg = false;
    let nv = ''
    for(const x of value){

      if(!isNaN(Number(x))){
        nv += x;
      }else{
        dispMsg = true;
      }
    }
    setDDSortCode(nv)

    if(dispMsg){
      setDDSortCodeMessage('Please only enter numbers');
    }
    else{
      setDDSortCodeMessage('');
    }
  }

  const setDDDay = (value: any) => {
    if(!value){
      return
    }

    if(basket == null){
      return;
    }

    setSchedUpdating(true);

    paymentPayMonthly(
      userToken,
      value.value,
      sessionId || ''
    ).then((d: any) => {
      if (d.basket_payment_plan != null) {
        if (d.basket_payment_plan.result != null) {
          const instalments: any[] = [];
          if (d.basket_payment_plan.result.plan.length > 0) {
            d.basket_payment_plan.result.plan.forEach((plan: any) => {
              instalments.push({ price: plan.amount, date: plan.date_due });
            });
            setDDInstallments(instalments);
            setSchedUpdating(false);
            setSelectedDDDay(value);
          }
        }
      }
    });

  }


  const directDebitSched = (showDayChange=false) => {

    let daySelector = null;
    if(showDayChange){

      const scExpYear = dropDownStyle();
      daySelector = <div style={{marginBottom: '10px'}}>
        <div>
          Preferred payment date
        </div>
        <div>
        <Select
              onChange={(e: any) => setDDDay(e)}
              inputId='expiryYear'
              options={dayOptions}
              menuShouldBlockScroll={false}
              styles={scExpYear}
              components={{ DropdownIndicator: CustomDropdownIndicator as any }}
              placeholder={'Please select an option'}
              value={selectedDay}
            />
        </div>

        
        </div>
    }

 
    if(schedUpdating){
    return(<div className='credit-card-grid__item'>
    {daySelector}
  <div style={{borderColor: configuration.border_colour, zIndex:'0'}} className='select-payment-container-child select-payment-container-child--credit-card select-payment-container-child--dd-overview'>

    <div style={{display:'flex', justifyContent:'center', alignItems: 'center', paddingBottom: '30px'}}>
    <LoaderSpinner />
    </div>
   
    <div className='credit-card-direct-debit-footer'>
      <p className='credit-card-direct-debit-footer__text'>
        Your payments are protected by the{' '}
        <a
          href='https://www.directdebit.co.uk/direct-debit-explained/direct-debit-guarantee/'
          rel='noreferrer'
          target='_blank'
        >
          Direct Debit Guarantee
        </a>
      </p>

      <img src={logo} width={110} alt='Direct Debit Guarantee' />
    </div>
  </div>
</div>)

 
  }

    return <div className='credit-card-grid__item'>
      {daySelector}
    <div style={{borderColor: configuration.border_colour, zIndex:'0'}} className='select-payment-container-child select-payment-container-child--credit-card select-payment-container-child--dd-overview'>

      {ddInstallments &&
        ddInstallments.map((instalment, index) => {
          return (
            <div className='select-payment-breakdown' key={'instalment' + index}>
              <div className='select-payment-circle'>
                {ddInstallments.length !== index + 1 && (
                  <div className='select-payment-circle-line select-payment-circle-line' />
                )}
              </div>
              <div className='select-payment-breakdown-title select-payment-breakdown-title-with-date'>
                <b>
                  {index + 1 === ddInstallments.length
                    ? 'Final Payment'
                    : `Instalment ${index + 1}`}
                </b>
                <div className='select-payment-breakdown-date'>
                  {dayjs(instalment.date).format('DD/MM/YY')}
                </div>
              </div>
              <div className='select-payment-breakdown-price'>
                {' '}
                £{instalment.price.toFixed(2)}{' '}
              </div>
            </div>
          );
        })}

      <div className='credit-card-direct-debit-footer'>
        <p className='credit-card-direct-debit-footer__text'>
          Your payments are protected by the{' '}
          <a
            href='https://www.directdebit.co.uk/direct-debit-explained/direct-debit-guarantee/'
            rel='noreferrer'
            target='_blank'
          >
            Direct Debit Guarantee
          </a>
        </p>

        <img src={logo} width={110} alt='Direct Debit Guarantee' />
      </div>
    </div>
  </div>
  }

  const ddForm = () => {

    const acchname = {borderColor: ''};
    const bankname = {borderColor: ''};
    const accnum = {borderColor: ''};
    const sortcode = {borderColor: ''};
    const bankadd = {borderColor: ''};
    const bankpc = {borderColor: ''};


    if(ddFormVal != null && !ddFormVal){
      if(ddAccountHolderName.trim() == ''){
        acchname.borderColor = 'red';
      }
      if(ddBankName.trim() == ''){
        bankname.borderColor = 'red';
      }
      if(ddAccountNumnber.trim() == ''){
        accnum.borderColor = 'red';
      }

      if(ddAccountNumnber.trim().length != 8){
        accnum.borderColor = 'red';
      }


      const sc = ddSortCode.replaceAll('-', '');

      if(sc.trim() == ''){
        sortcode.borderColor = 'red';
      }

      if(sc.trim().length != 6){
        sortcode.borderColor = 'red';
      }

      if(ddBankAddress.trim() == ''){
        bankadd.borderColor = 'red';
      }      
      if(ddBankPostCode.trim() == ''){
        bankpc.borderColor = 'red';
      }
    }


    return <div className='credit-card-grid dd-form'>
    <div className='credit-card-grid__item'>
      <div className='dd-form'>
        <div  className='credit-card-input-container'>
          <label aria-required={true} className='credit-card-label'>
            Account Holder Name
          </label>
          <div style={acchname} className='credit-card-input'>
            <input
              id='ddAccName'
              onChange={(e) => setDDAccountHolderName(e.currentTarget.value)}
              type='text'
              name='ddAccName'
              value={ddAccountHolderName}
              autoComplete='ddAccName'
            />   
          </div>
        </div>

        <div className='credit-card-input-container'>
          <label aria-required={true} className='credit-card-label'>
            Name of Bank
          </label>
          <div style={bankname} className='credit-card-input'>
            <input
              id='ddBankName'
              onChange={(e) => setDDBankName(e.currentTarget.value)}
              type='text'
              name='ddBankName'
              value={ddBankName}
              autoComplete='ddBankName'
            />
          </div>
        </div>

        <div className='dd-form__split-inputs'>
          <div className='credit-card-input-container'>
            <label aria-required={true} className='credit-card-label'>
              Account Number
            </label>
            <div style={accnum} className='credit-card-input'>
              <input
                id='ddAccNumber'
                onChange={(e) => validateAccNumber(e.currentTarget.value)}
                type='text'
                name='ddAccNumber'
                value={ddAccountNumnber}
                autoComplete='ddAccNumber'
              />
            </div>
            <label className='credit-card-label'>
                <span style={{color:'red'}}>{ddAccountNumnberMessage}</span>
            </label>
          </div>

          <div className='credit-card-input-container'>
            <label aria-required={true} className='credit-card-label'>
              Sort Code
            </label>
            <div style={sortcode} className='credit-card-input'>
              <input
                id='ddSortCode'
                onChange={(e) => validateSortCode(e.currentTarget.value)}
                type='text'
                name='ddSortCode'
                value={ddSortCode}
                autoComplete='ddSortCode'
              />
            </div>
            <label className='credit-card-label'>
                <span style={{color:'red'}}>{ddSortCodeMessage}</span>
            </label>
        </div>
        </div>

        {/* <div className='credit-card-input-container'>
          <label aria-required={true} className='credit-card-label'>
            Payment Day
          </label>
          <div>
              <Select
                onChange={(e: any) => setDDPayDay(e)}
                inputId='payDay'
                options={dayOptions}
                menuShouldBlockScroll={false}
                styles={StylesSelect}
                components={{ DropdownIndicator: CustomDropdownIndicator as any }}
                value={ddPayDay}
                />
          </div>
        </div> */}

  
      </div>
    </div>
  </div>
  }

  const dropDownStyle = () => {
    return {
      container: (base: any) => ({
        ...base,
        marginTop: '10px',
        color: configuration.text_colour,
      }),
      control: (base: any) => ({
        ...base,
        borderColor: configuration.border_colour_2,
      }),
      singleValue: (base: any) => ({
        ...base,
        color: configuration.text_colour,
      }),
      indicatorSeparator: (base: any) => ({
        ...base,
        marginTop: 0,
        marginBottom: 0,
      }),
      menu: (base: any) => ({
        ...base,
        borderColor: configuration.border_colour_2,
        backgroundColor: 'white',
        position: 'absolute',
        color: configuration.text_colour,
      }),
    };
  }

  const dropDownErrorStyle = () => {
    return {
      container: (base: any) => ({
        ...base,
        marginTop: '10px',
        color: configuration.text_colour,
      }),
      control: (base: any) => ({
        ...base,
        borderColor: 'red',
      }),
      singleValue: (base: any) => ({
        ...base,
        color: configuration.text_colour,
      }),
      indicatorSeparator: (base: any) => ({
        ...base,
        marginTop: 0,
        marginBottom: 0,
      }),
      menu: (base: any) => ({
        ...base,
        borderColor: configuration.border_colour_2,
        backgroundColor: 'white',
        position: 'absolute',
        color: configuration.text_colour,
      }),
    };

  }

  const payErrorSec = () => {
    return (<div className='credit-card-error'>
    <div id="errorSec" className='credit-card-error__left'>
      <svg
         xmlns='http://www.w3.org/2000/svg'
         fill='none'
         viewBox='0 0 24 24'
         strokeWidth={1.5}
         stroke='#fff'
       >
         <path
           strokeLinecap='round'
           strokeLinejoin='round'
           d='M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z'
         />
       </svg>

       <p className='credit-card-error__text'>
         There was a problem processing your payment, please try again.
       </p>
     </div>

     <button onClick={() => setShowErrorMessage(false)} type='button'> 
       <svg
         xmlns='http://www.w3.org/2000/svg'
         fill='none'
         viewBox='0 0 24 24'
         strokeWidth={1.5}
         stroke='#fff'
       >
         <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
       </svg>

       <span style={{ visibility: 'hidden' }}>Close</span>
     </button>
   </div>)
  }


  const payForm = () => {

    const cardname = {borderColor: ''};
    const cardno = {borderColor: ''};
    const cvv = {borderColor: ''};

    let scExpMonth = dropDownStyle();
    let scExpYear = dropDownStyle();


    if(payFormVal != null && !payFormVal){

      if(cardName.trim() == ''){
        cardname.borderColor = 'red';
      }
      if(cardNumber.trim() == ''){
        cardno.borderColor = 'red';
      }
      if(cardCVV.trim() == ''){
        cvv.borderColor = 'red';
      }
      if(expiryMonth == null){
        scExpMonth = dropDownErrorStyle();
      }
      if(expiryYear == null){
        scExpYear = dropDownErrorStyle();
      }      
    }

    let errSec = null;
    if(showErrorMessage){
      errSec = payErrorSec();
    }

    return  <>{errSec}<div id='payForm' className='credit-card-grid__item credit-card-grid__item--order-2-touch'>
    <div>
      <div className='credit-card-input-container'>
        <label aria-required={true} className='credit-card-label'>
          Name on Card
        </label>
        <div style={cardname} className='credit-card-input'>
          <input
            style={{borderColor: configuration.border_colour_2}}
            id='cardName'
            onChange={(e) => setCardName(e.currentTarget.value)}
            type='text'
            name='cc-name'
            autoComplete='cc-name'
            value={cardName}
          />
        </div>
      </div>
      <div className='credit-card-input-container'>
        <label aria-required={true} className='credit-card-label'>
          Card Number
        </label>
        <div style={cardno} className='credit-card-input'>
        <input
          style={{borderColor: configuration.border_colour_2}}
          onChange={(e) => setCardNumber(e.currentTarget.value)}
          id='cardNumber'
          type='text'
          name='cardnumber'
          autoComplete='cc-number'
          value={cardNumber}
        />
        </div>
      </div>
    </div>
    <div className=''>
      <div className='credit-card-input-container'>
        <label aria-required={true} className='credit-card-label'>
          Expiry Month
        </label>
 
          <Select
            onChange={(e: any) => setExpiryMonth(e)}
            inputId='expiryMonth'
            options={datesOptions}
            menuShouldBlockScroll={false}
            styles={scExpMonth}
            components={{ DropdownIndicator: CustomDropdownIndicator as any }}
            value={expiryMonth}
            />
      
      </div>
      <div className='credit-card-input-container'>
        <label aria-required={true} className='credit-card-label'>
          Expiry Year
        </label>

          <Select
            onChange={(e: any) => setExpiryYear(e)}
            inputId='expiryYear'
            options={yearOptions}
            menuShouldBlockScroll={false}
            styles={scExpYear}
            components={{ DropdownIndicator: CustomDropdownIndicator as any }}
            value={expiryYear}
          />
 
      </div>

      <div className='credit-card-cvv-wrap'>
        <div className='credit-card-input-container'>
          <label aria-required={true} className='credit-card-label'>
            CVV Number
          </label>
          <div style={cvv} className='credit-card-input'>
          <input
            onChange={(e) => setCardCVV(e.currentTarget.value)}
            id={'cvvNumber'}
            type='text'
            value={cardCVV}
          />
          </div>
        </div>

        <div>
          <img className='credit-card-cvv' src={cvvIco} height={75} width={75} />
        </div>
      </div>
    </div>
  </div></>
  }

  const payFooter = () => {
    return <><div className='credit-card-book-now'></div>
    <div className='grid-3-3-safe-checkout'>
      <div style={{color: configuration.text_colour}} className='credit-card-pay-with-card'>guaranteed safe checkout</div>
    </div>
    <div className='grid-3by3 grid-3by3-mob'>
      <div className='credit-card-align-right'>
        <img className='credit-card-visa-img' src={Visa} />
      </div>
      <div className='credit-card-align-center'>
        <img className='credit-card-mastercard-img' src={Mastercard} />
      </div>
      <div className='credit-card-ssl-container grid-custom-mob'>
        <img className='credit-card-ssl-img' src={Secureshield} />
        <span className='credit-card-ssl'>Secure SSL</span>
      </div>
    </div>
    </>
  }

  const paymentMethodSec = () => {

    if(paymentMethod == null){
      return <div style={{borderColor: configuration.border_colour, marginBottom: '20px'}} className='select-payment-container-child select-payment-container-child--credit-card'>
        <div className='select-payment-breakdown select-payment-breakdown--credit-card'>
          <div style={{backgroundColor: configuration.jrny.payment_option_colour}} className='select-payment-circle select-payment-circle-active'></div>
          <div className='select-payment-breakdown-title'>
            <b>Full payment</b>
          </div>

          <div className='select-payment-breakdown-price'>
            £{basket.price || 0}
          </div>
        </div>
        </div>  
    }

    if(paymentMethod.force_payment_method != 'dd'){
      return <div style={{borderColor: configuration.border_colour, marginBottom: '20px'}} className='select-payment-container-child select-payment-container-child--credit-card'>
      <div className='select-payment-breakdown'>
        <div style={{backgroundColor: configuration.jrny.payment_option_colour}} className='select-payment-circle select-payment-circle-active'>
          <div style={{backgroundColor: configuration.jrny.payment_option_colour}}  className='select-payment-circle-line select-payment-circle-line-active' />
        </div>
  
        <div className='select-payment-breakdown-title'>
          <b>Pay today</b>
        </div>
  
        <div className='select-payment-breakdown-price'>£{paymentMethod.basket_price.toFixed(2)}</div>
      </div>
  
      <div className='select-payment-breakdown'>
        <div className='select-payment-circle' />
        <div className='select-payment-breakdown-title'>
          <b>Final payment</b>
    
            <div className='select-payment-breakdown-date'>
              {dayjs(paymentMethod.balance_due).format('DD/MM/YY')}
            </div>
        
        </div>
        <div className='select-payment-breakdown-price'> £{paymentMethod.outstanding_balance.toFixed(2)} </div>
        </div>
    </div>
    }

    if(paymentMethod?.force_payment_method == 'dd'){
      return <div style={{borderColor: configuration.border_colour, marginBottom: '20px'}} className='select-payment-container-child select-payment-container-child--credit-card'>
      <div className='select-payment-breakdown select-payment-breakdown--credit-card'>
        <div style={{backgroundColor: configuration.jrny.payment_option_colour}} className='select-payment-circle select-payment-circle-active'></div>
        <div className='select-payment-breakdown-title'>
          <b>Pay today</b>
        </div>

        <div className='select-payment-breakdown-price'>£{paymentMethod.basket_price.toFixed(2)}</div>
      </div>
      </div>  
    }

    return null;
  }

  const validatePayForm = () => {

    if(cardName.trim() == ''){
      return false;
    }
    if(cardNumber.trim() == ''){
      return false;
    }
    if(cardCVV.trim() == ''){
      return false;
    }
    if(expiryMonth == null){
      return false;
    }
    if(expiryYear == null){
      return false;
    }     

    return true;
  }

  const cancelDirectDeibtProcess = () => {

    if(basket.price != null && onPriceChange){
      onPriceChange(basket.price, null)
    }

  }

  // The book now click
  const bookNow = () => {

    setLoadingIndicator(true);
    const v = validatePayForm();

    setPayFormVal(v);

    if(v){
      sessionStorage.removeItem('ddDetail');
      if(paymentMethod?.force_payment_method == 'dd'){
        const ddDetail = {'accountHolder': ddAccountHolderName, 'accountNumber': ddAccountNumnber,
      'sortCode': ddSortCode, 'bankName': ddBankName, 
      'bankAddress': ddBankAddress, 'bankPostCode': ddBankPostCode, 'planID': paymentPlan}
        sessionStorage.setItem('ddDetail', JSON.stringify(ddDetail));
      }

      payment();
    }
    else{
      setLoadingIndicator(false);
    }

  }

  const payment = async () => {
    let bookingResults: any = {};
    let _bookingId = bookingId;

    // if (showErrorMessage) {
    //   paymentError(false);
    // }


    const takePayment = await siteConfiguration('23', userToken)

    await productCheckAll(userToken, sessionId);

    const toleranceCheck = await productToleranceCheck(userToken, sessionId);
  
    //if cookie is present allocate the booking to agent
    let agentId = -1;
    const exA = getCookie('agt', true) as Agent;
    if(exA != null){
      agentId = exA.id;
    }

    let depositOptionID = -1;
    let amountToPay = price;
    if (paymentMethod != null) {
      depositOptionID = paymentMethod.id;
      amountToPay = paymentMethod.basket_price;
    }

    bookingResults = await makeBooking(userToken, sessionId, depositOptionID, agentId);
    setBookingId(bookingResults?.basket_new_booking?.result?.booking.id);
    _bookingId = bookingResults?.basket_new_booking?.result?.booking.id;

  
    if(toleranceCheck.product_tolerance_check.result == 0){
      //Get out of here price has changed beyond tolerance configuration
      showSuccess(_bookingId);
      return;
    }

    if(takePayment == 0){
      showSuccess(_bookingId)
      return;
    }


    if (onBooking) onBooking(_bookingId);

    if (_bookingId !== 0) {

      //Hays bypass live pay 
      // if(configuration.spec == "6yk1y" && amountToPay == 0 && paymentMethod == 1){
      //   await bookProds(_bookingId);
      //   return
      // }
 
      const paymentResults = await paymentStepOne(
        userToken,
        cardNumber,
        cardName,
        parseInt(expiryMonth.value),
        parseInt(expiryYear.value),
        cardCVV,
        depositOptionID,
        _bookingId,
        sessionId,
      );

      if (paymentResults?.booking_pay?.result?.payment_session) {
        setPaymentSession(paymentResults.booking_pay.result.payment_session);
      }

      if (paymentResults == null) {
        setLoadingIndicator(false);
        if (!showErrorMessage) {
          setShowErrorMessage(true);
        }
        return;
      }

      if (paymentResults.booking_pay.result.pgw_response == 'pending') {
        showSuccess(_bookingId);
        return;
      }

      const paymentStepTwoResults = await paymentStepTwo(
        userToken,
        cardNumber,
        cardName,
        parseInt(expiryMonth.value),
        parseInt(expiryYear.value),
        cardCVV,
        depositOptionID,
        _bookingId,
        paymentResults.booking_pay?.result.payment_session,
        paymentResults.booking_pay.result.pgw_response,
        sessionId,
      );

      if (paymentStepTwoResults == null) {
        setLoadingIndicator(false);
        setShowErrorMessage(true);
        return;
      }

      if (paymentStepTwoResults.booking_pay.result.completed === 1) {
        if (paymentStepTwoResults.booking_pay.result.payment_result == 'fail') {
          setLoadingIndicator(false);
  
          setShowErrorMessage(true);
        } else {
          //Otherwise redirect to book products page
          await bookProds(_bookingId);
        }
      } else {
        // Payment session still not completed. 3D secure auth required
        let t = paymentStepTwoResults.booking_pay.result.pgw_response;
        t = t.replaceAll('"', '');
        setPGWJWT(t);
        setPaymentResult(paymentStepTwoResults.booking_pay.result);
        set3DSecure(true);
      }
    }
  };

  const strikeTitle = (title: string) => {
    return          <div style={{display:'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', marginBottom: '15px'}}>
    <hr style={{width:'100%', position: 'absolute', top: '18px', borderColor: configuration.jrny.border_colou}}></hr>
    <p style={{zIndex: '5', background: 'white', paddingLeft: '15px', paddingRight: '15px'}}>{title}</p>
  </div>
  }

  let cvvIco = CVVIconDefault;
  let lockIco = lockIconDefault;
  if(configuration.icon_set == 'blue'){
    cvvIco = CVVIcon;
    lockIco = lockIcon
  }

  let atolIco = atolIconDefault;
  let abtaIco = atbaIconDefault;

  if(configuration.icon_set == 'blue'){
    atolIco = Atol;
    abtaIco = Atba;
  }


  if(paymentSuccess){
    return (<div className={`credit-card-container ${!completedIndicator ? 'credit-card-hidden' : ''}`}>
    <div className='credit-card-loader-text'>Your payment was successful.</div>
    <div className='credit-card-loader'>
      <div className='credit-card-rel-container'>
        <div className='credit-card-circle'>
          <img className='credit-card-tick' src={Tick} height={100} width={100} />
        </div>
      </div>
      <div className='credit-card-loader-tag'>Peace of mind</div>
      <div className='credit-card-flex-center'>
        <div>
          <img src={abtaIco} height={170} width={170} />
        </div>
        <div>
          <img src={atolIco} height={75} width={75} />
        </div>
      </div>
    </div>
  </div>)
  }


  if(is3DSecure && paymentResult != null){

    //this may depend on what is returned here
    if(paymentResult.pgw_id == 4){

      const pgwJson = JSON.parse(paymentResult.pgw_response);
      //pxp
      return (<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid ' + configuration.jrny.border_colour, padding: '10px', borderRadius: '12px'}}>
        <iframe src={pgwJson.redirect_url} style={{height: '600px'}}></iframe>
      </div>)

    }
    else{
      //World pay (our default implmentation)
      return (<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid ' + configuration.jrny.border_colour, padding: '10px', borderRadius: '12px'}}>
        <IFrame style={{ height: '600px' }}>
        <form
          id='challengeForm'
          ref={gatewayForm}
          method='POST'
          action='https://centinelapi.cardinalcommerce.com/V2/Cruise/StepUp'>
          <input type='hidden' name='MD' value={bookingId} />
          <input type='hidden' name='JWT' value={`${PGWJWT}`} />
        </form>
      </IFrame></div>)
    }


  }


  if(loadingIndicator){
    return (<div className='credit-card-container'>
    <div className='credit-card-loader-text'>Processing your payment.</div>
    <div className='credit-card-loader'>
      <div className='credit-card-rel-container'>
        <LoaderSpinner />
        <img
          className='credit-card-img-padlock-absolute'
          src={lockIco}
          height={75}
          width={75}
        />
      </div>
      <div className='credit-card-loader-tag'>Peace of mind</div>
      <div className='credit-card-flex-center'>
        <div>
          <img src={abtaIco} height={170} width={170} />
        </div>
        <div>
          <img src={atolIco} height={75} width={75} />
        </div>
      </div>
    </div>
  </div>)
  }

  // Direct Debit
  if(paymentMethod?.force_payment_method == 'dd'){

    const chkStyle = {display: 'block', width: '45px', height: '45px', borderStyle:'solid', borderWidth: '1px', borderRadius: '6px', cursor: 'default', borderColor: '#99b4ca'};
    const spanSelected = <span style={{display: 'flex', width: '45px', height: '45px', color: 'white', alignItems: 'center', justifyContent: 'center'}}><img src={Tick} style={{color: 'white'}} height='30' width='30' /></span>
    const lblStyle = {marginLeft: '10px'};

    // Show the DD payment form
    if(ddShowPaymentForm && ddFormYesVal){
      return(<>      
        <div style={{borderColor: configuration.jrny.border_colour}} className={'credit-card-container'}>
        <h4 className='credit-card-title'>
          <img className='credit-card-icon' src={lockIco} height='30' width='30' />
          <span>Payment Method</span>
        </h4>

        <div onClick={() => backtoDDForm()} style={{position: 'relative', height: '30px', marginBottom: '15px', marginTop: '15px'}}>
          <i style={{position: 'absolute'}} className="arrow left"></i>
          <p><span style={{position: 'absolute', left: '40px', top: '9px'}}>Back to Direct Debit</span></p>
        </div>

        {strikeTitle('Pay Deposit')}

        <div className='pay-method-con'>

          <div className='pc1'>
          {paymentMethodSec()}  
          </div>

          <div className='pc2'>
          <div className='credit-card-grid'>
              <div className='credit-card-grid__item credit-card-grid__item--order-1-touch'>
                <p className='credit-card-title'>
                  <b>Credit / Debit Card</b>
                </p>
          
         
                {payForm()}
            </div>
            </div> 

        </div>
        </div>

   
      <CreditCardTerms configuration={configuration} callback={(flag: boolean) => setAcceptsTerms(flag)} />
      <Button disabled={!acceptsTerms} onClick={() => bookNow()} backgroundColor={configuration.button_colour} borderColor={configuration.button_colour} label='Book now' primary={true}/>        
      {payFooter()}
      </div>
      </>);
    }
    else
      {

        if(ddFormConfirmVal != null){
            
          if(!ddFormConfirmVal){
            chkStyle.borderColor = 'red';
          }
        }

        if(ddVal){

            let ddConfirmFormInput = null;
            if(ddFormCompleted){
          
              let aTick = null;   
              let isDDFormYesSelected = false;
              if(ddFormYesVal){
                  isDDFormYesSelected = true;
                  aTick = spanSelected
              }    
              
              let bTick = null;   
              let isDDFormNoSelected = false;
              if(ddFormNoVal){
                isDDFormNoSelected = true;
                bTick = spanSelected
              }
        
              ddConfirmFormInput = <div className='debit_question'>
              <div><p>Please check the details are correct and select Yes or No to confirm the details</p></div>
              <div style={{display: 'flex', alignItems: 'center', flexWrap: 'nowrap', width: '100%', justifyContent: 'center', marginBottom: '35px'}}>
                  <div>
                      <div style={{alignItems: 'center', display: 'flex', marginRight:'100px'}}>
                        <input onChange={(e) => {setDDFYes(e.currentTarget.checked)}} style={{height: '0px', width: '0px', display: 'none'}} type='checkbox' id={'confDDFmYes'} name={'confDDFmYes'} checked={isDDFormYesSelected} />
                        <label style={chkStyle} htmlFor={'confDDFmYes'}>{aTick}</label>
                        <label style={lblStyle} htmlFor={'confDDFmYes'}>{'Yes'}</label>
                      </div>
                  </div>
                  <div>
                      <div style={{alignItems: 'center', display: 'flex'}}>
                        <input onChange={(e) => {setDDFNo(e.currentTarget.checked)}} style={{height: '0px', width: '0px', display: 'none'}} type='checkbox' id={'confDDFmNo'} name={'confDDFmNo'} checked={isDDFormNoSelected} />
                        <label style={chkStyle} htmlFor={'confDDFmNo'}>{bTick}</label>
                        <label style={lblStyle} htmlFor={'confDDFmNo'}>{'No'}</label>
                      </div>
                  </div>
              </div>
            </div>
            }
        
            return(<>
        <div style={{borderColor: configuration.jrny.border_colour}}
              className={`credit-card-container ${
                loadingIndicator || completedIndicator ? 'credit-card-hidden' : ''}`}>
                <h4 className='credit-card-title'><img className='credit-card-icon' src={lockIco} height='30' width='30' /><span>Payment Method</span></h4>


                {strikeTitle('Setup Direct Debit')}


                <div className='pay-method-con'>

                  <div className='pc1'>
                      {directDebitSched(true)}
                  </div>

                  <div className='pc2'>
                      {ddForm()}
                      {ddConfirmFormInput}
              
                      <div style={{display:'flex', justifyContent: 'center', gap: '25px', flexWrap: 'nowrap'}}>
                        <div style={{width: '150px'}}>
                        <button style={{color: configuration.text_colour}} className='button' onClick={() => {cancelDDForm();}}>Cancel</button>
                        </div>
                        <div style={{width: '150px'}}>
                            <button className='button' onClick={() => {continueDDForm();}} style={{borderColor: configuration.button_colour, backgroundColor: configuration.button_colour, color: 'white'}}>Continue</button>
                        </div>
                      </div>
                  </div>

                </div>

        
              
          
        
                </div>
            </>)
        }
        else{
        
          let aTick = null;   
          let isASelected = false;
          if(ddA){
              isASelected = true;
              aTick = spanSelected
          }    
          
          let bTick = null;   
          let isBSelected = false;
          if(ddB){
            isBSelected = true;
            bTick = spanSelected
          }
      
          let cTick = null;   
          let isCSelected = false;
          if(ddC){
            isCSelected = true;
            cTick = spanSelected
          }
      
          let dTick = null;   
          let isDSelected = false;
          if(ddD){
            isDSelected = true;
            dTick = spanSelected
          }
      
          chkStyle.borderColor ='#99b4ca';
          if(ddVal != null){
            
            if(!ddVal){
              chkStyle.borderColor = 'red';
            }
          }
      
          return(<><div style={{borderColor: configuration.jrny.border_colour}}
            className={`credit-card-container ${
              loadingIndicator || completedIndicator ? 'credit-card-hidden' : ''}`}>
              <h4 className='credit-card-title'><img className='credit-card-icon' src={lockIco} height='30' width='30' /><span>Payment Method</span></h4>

              {strikeTitle('Setup Direct Debit')}


              <div className='pay-method-con'>

              <div className='pc1'>
              {directDebitSched()}
              </div>
      
              <div className='pc2'>
              <div className='debit_question'>
                  <div><p>Please can you confirm you hold a UK Bank / Building Society account and you are the account holder ?</p></div>
                  <div style={{display: 'flex', alignItems: 'center', flexWrap: 'nowrap', width: '100%', justifyContent: 'center'}}>
                      <div>
                          <div style={{alignItems: 'center', display: 'flex', marginRight:'100px'}}>
                            <input onChange={(e) => {setDirectA(e.currentTarget.checked)}} style={{height: '0px', width: '0px', display: 'none'}} type='checkbox' id={'confA'} name={'confA'} checked={isASelected} />
                            <label style={chkStyle} htmlFor={'confA'}>{aTick}</label>
                            <label style={lblStyle} htmlFor={'confA'}>{'Yes'}</label>
                          </div>
                      </div>
                      <div>
                          <div style={{alignItems: 'center', display: 'flex'}}>
                            <input onChange={(e) => {setDirectB(e.currentTarget.checked)}} style={{height: '0px', width: '0px', display: 'none'}} type='checkbox' id={'confB'} name={'confB'} checked={isBSelected} />
                            <label style={chkStyle} htmlFor={'confB'}>{bTick}</label>
                            <label style={lblStyle} htmlFor={'confB'}>{'No'}</label>
                          </div>
                      </div>
                  </div>
                </div>
      
                <div className='debit_question'>
                  <div><p>Please can you confirm you are the only person required to authorise debits from this account?</p></div>
                  <div style={{display: 'flex', alignItems: 'center', flexWrap: 'nowrap', width: '100%', justifyContent: 'center'}}>
                    <div>
                        <div style={{alignItems: 'center', display: 'flex', marginRight:'100px'}}>
                            <input onChange={(e) => {setDirectC(e.currentTarget.checked)}} style={{height: '0px', width: '0px', display: 'none'}} type='checkbox' id={'confC'} name={'confC'} checked={isCSelected} />
                            <label style={chkStyle} htmlFor={'confC'}>{cTick}</label>
                            <label style={lblStyle} htmlFor={'confC'}>{'Yes'}</label>
                          </div>
                      </div>
                      <div>
                          <div style={{alignItems: 'center', display: 'flex'}}>
                            <input onChange={(e) => {setDirectD(e.currentTarget.checked)}} style={{height: '0px', width: '0px', display: 'none'}} type='checkbox' id={'confD'} name={'confD'} checked={isDSelected} />
                            <label style={chkStyle} htmlFor={'confD'}>{dTick}</label>
                            <label style={lblStyle} htmlFor={'confD'}>{'No'}</label>
                          </div>
                      </div>
                  </div>
                </div>
      
                <div>
                  <p>
                    If you have answered No to either of these questions you will need to contact our customer team to discuss alternatives on 0800 408 4048 or select Low Deposit or Pay in Full.
                  </p>
                  <p>
                    If you do not wish to proceed any further click cancel.
                  </p>
                  <p>
                    If you are happy to proceed, please click continue.
                  </p>
                </div>
      
              
                <div style={{display:'flex', justifyContent: 'center', gap: '25px', flexWrap: 'nowrap'}}>
                  
                  <div style={{width: '150px'}}>
                  <button
                    className='button'
                    style={{color: configuration.text_colour}}
                    onClick={() => {
                      cancelDirectDeibtProcess();
                      window.location.href = '#selectPaymentFormFullOp';
                    }}
                    > Cancel</button>
                  </div>

                  <div style={{width: '150px'}}>
                  <button
                    className='button'
                    onClick={() => {
                      continueDD();
                    }}
                    style={{borderColor: configuration.button_colour, backgroundColor: configuration.button_colour, color: 'white'}}

                  >Continue</button>
                  </div>
         
                </div>
              </div>

              </div>

    
      
        
      
              </div>
      
            
          </>)
      }
    }
  }

  let payTitle = 'Pay Total'
  if(paymentMethod){
    payTitle = 'Pay Deposit'
  }


  return(<>      
    <div style={{borderColor: configuration.jrny.border_colour}} className={'credit-card-container'}>
    <h4 className='credit-card-title'>
      <img className='credit-card-icon' src={lockIco} height='30' width='30' />
      <span>Payment Method</span>
    </h4>

    {strikeTitle(payTitle)}

    <div className='pay-method-con'>

          <div className='pc1'>
          {paymentMethodSec()}  
          </div>

          <div className='pc2'>
          <div className='credit-card-grid'>
              <div className='credit-card-grid__item credit-card-grid__item--order-1-touch'>
                <p className='credit-card-title'>
                  <b>Credit / Debit Card</b>
                </p>
          
         
                {payForm()}
            </div>
            </div> 

        </div>
        </div>

  
  <CreditCardTerms configuration={configuration} callback={(flag: boolean) => setAcceptsTerms(flag)} />
  <Button disabled={!acceptsTerms} onClick={() => bookNow()} backgroundColor={configuration.button_colour} borderColor={configuration.button_colour} label='Book now' primary={true}/>        
  {payFooter()}
  </div>
  </>);
  
};
