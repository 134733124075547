import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import './PaymentOptions.scss';

import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat'

import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { DepositOption } from '../../hooks/types';

type PaymentOptionsProps = {
  pricePlan: any;
  live: boolean;
  depositOptions: DepositOption[];
};

type Options = { title: string; items: { title: string; value: string }[] }[];

export function PaymentOptions({ pricePlan, live=false, depositOptions=[]}: PaymentOptionsProps) {

  dayjs.extend(AdvancedFormat)
  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };
  
  const [options, setOptions] = useState<Options>([]);
  
  useEffect(() => {
      const plans: any[] = [];
      let tf = 0;
      if(live){
        tf = 2;
      }
      for(const x of depositOptions){

         if(x.force_payment_method == "dd" && pricePlan != null){

            const avgAmount = x.outstanding_balance / pricePlan.plan.length;

            plans.push({
              title: x.description,
              items: [
                {
                  title: 'Due now',
                  value: `£${x.basket_price_per_person.toFixed(tf)} pp`,
                },
                {
                  title: `${pricePlan.plan.length} x monthly payments`, 
                  value: `£${avgAmount.toFixed(tf)}`,
                }
              ],
            })
          continue
         }

          plans.push({
            title: x.description,
            items: [
              {
                title: 'Due now',
                value: `£${x.basket_price_per_person.toFixed(tf)} pp`,
              },
              {
                title: 'Pay final balance',
                value:  dayjs(x.balance_due).format('Do MMMM YYYY')
           
              },
            ],
          })
      }
      setOptions(plans);
  }, [pricePlan]);

  if(options.length == 0){
    return null;
  }


  return (
    <div style={{borderColor: configuration.jrny.basket.border_colour}}  className='payment-options'>


        <div className='payment-options__content'>
          {options.map((option) => {
            return (
              <div className='payment-options__option' key={option.title}>
                <b className='payment-options__option-title'>{option.title}</b>

                {option.items.map((item) => {
                  return (
                    <div className='payment-options__option-item' key={item.title}>
                      <span>{item.title}</span>
                      <span>{item.value}</span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>

    </div>
  );
}
