import queryString from 'query-string';
import dayjs from 'dayjs';
import { noop } from 'lodash';
import { useEffect, useState } from 'react';
import Map from '../../components/MapModal/Map';

import { PageLoader } from '../../components/PageLoader';
import { BaseLayout } from '../../components/BaseLayout';
import { getPackageDetails, packagePaymentPlan, paymentPlan as statelessPaymentPlan, accommodationAdd, 
  sessionUpdateSelections, packageSearchStateless, packageSearch, getAccommodation } from '../../hooks/useRequest';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { Rating } from '../../components/HolidayCard/Rating';
import { TripadvisorReviews } from '../../components/Tripadvisor';

import tripLogo from '../../components/assets/tripadvisor.svg';

import hotelIcon from '../../components/assets/hotel.svg';
import hotelIconDefault from '../../components/assets/icons/default/03/Hotel.svg';

import locationIcon from '../../components/assets/location.svg';
import locationIconDefault from '../../components/assets/icons/default/03/Map.svg';

import tripadvisorIcon from '../../components/assets/tripadvisor_logo.svg';

import { ImageSlider } from '../../components/ImageSlider/ImageSlider';

import '../../components/HolidayCard/NameSection/NameSection.scss';
import './PackagePage.scss';
import { TabsData } from '../../components/ModalWithTabs/ModalWithTabs';
import SimpleTabs from '../../components/ModalWithTabs/SimpleTabs';
import PackagePageLoader from './PackagePageLoader';
import { useSearchParams, useNavigate } from 'react-router-dom';

import {fbqTrackView, fbqIniCheckout} from '../../hooks/fb';

import { Accommodation, Package, SearchSelections } from '../../hooks/types';

import { roomsFromPaxString, roomsToString, roomsToStringURL } from '../../services/pax';
import { packgeProductIds, sessionSetSearchParams, sessionGetSearchParams,  } from '../../services/search';
import { clearSessionKeys } from '../../services/session';

import PackageHolidaySummary from '../../components/HolidaySummary/PackageHolidaySummary';
import { siteNavigate } from '../../services/nav';

import { LoaderSpinner } from '../../components/Loader/Loader';
// Images
import AtolIcon from '../../components/assets/atol.svg';
import AtbaIcon from '../../components/assets/atba.svg';

import atolIconDefault from '../../components/assets/icons/default/01/TOL.svg';
import abtaIconDefault from '../../components/assets/icons/default/01/ABTA.svg';

type PackagePageProps = {
  onLogin?: () => void;
  openMenu?: () => void;
};

export function PackagePage({ onLogin = noop, openMenu = noop }: PackagePageProps) {

  const [searchParams] = useSearchParams();

  const s = searchParams.get('session');
  const id = searchParams.get('package');
  const selectedProds = packgeProductIds(searchParams);

  const [sessionId] = useState<string>(s || '');

  const [loading, setLoading] = useState<boolean>(true);

  const [accommodation, setAccommodation] = useState<Accommodation | null>(null);

  const [offerFetching, setOfferFetching] = useState<boolean>(false);

  const [offerSelecting, setOfferSelecting] = useState<boolean>(false);

  const [holidayPackage, setHolidayPackage] = useState<Package | null>(null);
  const [activeTabNumber, setActiveTab] = useState<number>(0);

  const [paymentPlan, setPackagePayPlan] = useState<any>();

  const [isDumb, setIsDumb] = useState<boolean>(true);

  let duration = 7;
  if(searchParams.get('duration') != null){
    duration = Number(searchParams.get('duration'));
  }

  let rooms = searchParams.get('rooms') || '2-';


  const fNav = useNavigate();

  const {
    RootStore: {
      sessionStore: { userSession, createUserSession },
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  useEffect(() => {
    
    const fetchPackage = async () => {

      sessionStorage.removeItem('session_cr');

      if (sessionId && id) {

        setIsDumb(false);
        const response = (await getPackageDetails(
          userToken,
          sessionId,
          parseInt(id),
          selectedProds,
        ));

        if (response?.package.error) {
          alert('Unable to load package..');
          return console.log('Unable to load package..', response.package.error);
        }

        setHolidayPackage(response.package.result);
        setAccommodation(response.package.result.accommodation);

        let totaldepPrice = 0;
        let totalRmPrice = 0;
        for (const r of response.package.result.rooms) {
          totalRmPrice += r.price;
          totaldepPrice += r.deposit_price;
        }

        const pakPayPlan = (await packagePaymentPlan(
          userToken,
          sessionId,
          parseInt(id),
          selectedProds,
        )) as any;

        if (pakPayPlan != null) {
          setPackagePayPlan(pakPayPlan.package_payment_plan.result);
        }

        accommodationAdd(response.package.result.accommodation.id, '', 2, userToken);
        
        const o = response.package.result;
        const cin = dayjs(o.flight.outbound_arrival).format('YYYY-MM-DD');
        const cout = dayjs(o.flight.inbound_departure).format('YYYY-MM-DD');
        fbqTrackView(o.accommodation.resort.name, o.accommodation.resort.regions[0].name, o.accommodation.resort.regions[0].destinations[0].name, 
          cin, cout, o.accommodation.id, o.rooms[0].adults, o.rooms[0].children)
      }
      else{
        // stateless search 
 
        let rooms = searchParams.get('rooms') || '2-';

        let departureDate: any = null;
        const qsDeparting = searchParams.get('departing');
        if(qsDeparting != null){
          departureDate = qsDeparting + ' 00:00';
        }

        let selDeparturePoints: string[] = [];
        const qsDepPoints = searchParams.get('departurePoints');
        if(qsDepPoints != null){
          for(const x of qsDepPoints.split('-')){
              selDeparturePoints.push(x)
          }
        }

        let duration = 7;
        if(searchParams.get('duration') != null){
          duration = Number(searchParams.get('duration'));
        }

        let accid = getAccId();
        if(departureDate != null){

          setOfferFetching(true);
          const pss = await packageSearchStateless(userToken, rooms, selDeparturePoints[0], departureDate, duration, 0, [],[], accid)
          setIsDumb(false);
          if(pss.package_search_no_state.result.length > 0){

            let totalPrice = 0;
            let totalDeposit = 0;
            const gotRooms = [];
            for(const r of pss.package_search_no_state.result[0].rooms){
              if(r.deposit_options.length == 0){
                continue;
              }
              if(gotRooms.indexOf(r.room_number) != -1){
                continue
              }
              totalPrice += r.price;
              totalDeposit += r.deposit_options[0].basket_price;
              gotRooms.push(r.room_number);
            }

            if(totalDeposit > 0){
              const pakPayPlan = (await statelessPaymentPlan(
                userToken,
                1,
                dayjs(pss.package_search_no_state.result[0].flight.outbound_departure).format('YYYY-MM-DD 00:00'),
                totalPrice,
                totalDeposit
              )) as any;
              if (pakPayPlan != null) {
                setPackagePayPlan(pakPayPlan.payment_plan.result);
              }
            }


            setHolidayPackage(pss.package_search_no_state.result[0]);
            setAccommodation(pss.package_search_no_state.result[0].accommodation);
          }
          else{
            const accObj = await getAccommodation(userToken, accid, 11);
            setAccommodation(accObj.accommodation.result);
          }
        }
        else{
          const accObj = await getAccommodation(userToken, accid, 11);
          setAccommodation(accObj.accommodation.result);

          if(accObj != null){
            fNav(window.location.pathname + `?regions=${accObj.accommodation.result.resort.regions[0].id}`);
          }
        }


        setOfferFetching(false);
      }

      setLoading(false);
    };

    fetchPackage();
  }, []);


  const getAccId = () => {
    let accid = -1;
    const strurls = window.location.pathname.split('/');
    const stracc_id  = strurls[strurls.length - 1];
    accid = Number(stracc_id);
    return accid;
  }

  const selectOffer = async() => {

    setOfferSelecting(true);

    clearSessionKeys(['js1', 'js2', 'js3', 'js4', 'js5', 'shosnt', 'gap']);
    
    const o = holidayPackage;

    if(o != null){
      const cin = dayjs(o.flight.outbound_arrival).format('YYYY-MM-DD');
      const cout = dayjs(o.flight.inbound_departure).format('YYYY-MM-DD');
      fbqIniCheckout(o.accommodation.resort.name, o.accommodation.resort.regions[0].name, o.accommodation.resort.regions[0].destinations[0].name, 
        cin, cout, o.accommodation.id, o.rooms[0].adults, o.rooms[0].children)
    }

    const sp = sessionGetSearchParams();
    if(sp != null){
      sp.duration = duration;
      sp.rooms = roomsFromPaxString(rooms)
      sp.searchType = 2;
      sessionSetSearchParams(sp);
    }
    else{
      const selections: SearchSelections = { 
        duration: 7, destinations: [], regions: [], resorts: [], 
        departurePoints: [], departureDate: null, departureDateType: 0, searchType: 0, ratings: [], tripRatings: [], boards: [], rooms : [{adults: 2, children: 0, childAges: []}]}

      selections.duration = duration;
      selections.rooms = roomsFromPaxString(rooms)
      selections.searchType = 2;
      sessionSetSearchParams(selections);
    }

    let session = sessionId
    let oid = id;
    if(session == '' && o != null){
      session = await createUserSession(userToken, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 2, undefined, undefined) || '';

      let accid = getAccId();

      let departureDate: any = null;
      const qsDeparting = searchParams.get('departing');
      if(qsDeparting != null){
        departureDate = qsDeparting + ' 00:00';
      }

      await packageSearch(userToken, session, rooms, o.flight.departure_point.code, departureDate, o.duration, 0, [], [], accid);

    }
    if(oid == null){
      oid = o?.id.toString() || null;
    }

    if(selectedProds.length == 0){
      const colRooms = [];
      for(const r of o?.rooms || []){
        if(colRooms.indexOf(r.room_number) != -1){
          continue
        }
        selectedProds.push(r.id);
        colRooms.push(r.room_number);
      }
    }

    await sessionUpdateSelections(userToken, session, o?.accommodation.id, o?.flight.departure_point.code, o?.flight.arrival_airport.code, o?.flight.outbound_departure, o?.duration, o?.rooms[0].board, rooms, 2, selectedProds.join('-') + '$' + oid?.toString())
    
    setOfferSelecting(false);
    
    siteNavigate(configuration, `/package-select/?session=${session}`, fNav);

  };

  const TARatings = ({
    tripAdvisor,
    accommodation,
  }: {
    tripAdvisor: string;
    accommodation: Accommodation;
  }) => {
    if (tripAdvisor == '') {
      return <p>There are no reviews for this hotel.</p>;
    }

    const tripData = JSON.parse(tripAdvisor);

    return (
      <TripadvisorReviews
        hotelId={accommodation.id}
        hotelName={accommodation.name}
        tripadvisorRating={parseFloat(tripData.rating)}
      />
    );
  };

  const Ratings = ({ tripAdvisor, hotelRating }: { tripAdvisor: string; hotelRating: number }) => {

    if(tripAdvisor == ''){
      return null;
    }
    
    const tripData = JSON.parse(tripAdvisor);

    return (
      <div className='package-page-ratings'>
        <div className='tripadvisor-rating'>
          <img src={tripLogo} alt='' className='trip-logo' />
          <Rating type={'circle'} rating={tripData.rating} />
          <span className='review-details'>{tripData.num_reviews} reviews</span>
        </div>

        <div className='rating'>
          <Rating type='star' rating={hotelRating} />
        </div>
      </div>
    );
  };

  const ShowLoading = () => {
    return(<BaseLayout userToken={userToken} userConfiguration={configuration} >
      <div className='package-page'><div className='package-page-wrapper'>
      <PackagePageLoader config={configuration} />
        </div></div></BaseLayout>)
  }

  const showOfferFetching = () => {

    let atolIco = atolIconDefault;
    let abtaIco = abtaIconDefault;
    if(configuration.icon_set == 'blue'){
      atolIco = AtolIcon;
      abtaIco = AtbaIcon;
    }
  

    return(<BaseLayout userToken={userToken} userConfiguration={configuration} >
      <div className='package-page'><div className='package-page-wrapper'>
      <PackagePageLoader config={configuration} />


      <div className='package-search-loader-overlay'>
        <div className='package-search-loader-modal'>
          <p className='package-search-loader-modal__title'>Finding your holiday...</p>

          <div className='package-search-loader-modal__spinner'>
            <LoaderSpinner />
          </div>

          <p>Loading, this may take up to 30 seconds.</p>

          <div className='package-search-loader-modal__logos'>
            <img width={150} src={abtaIco} />
            <img width={50} src={atolIco} />
          </div>
        </div>
      </div>


        </div></div></BaseLayout>)

  }

  if(offerFetching){
    return showOfferFetching();
  }

  if(loading){
    return ShowLoading();
  }

  if(accommodation == null){
    return ShowLoading();
  }

  let hotelIco = hotelIconDefault;
  let mapIco = locationIconDefault
  if(configuration.icon_set == 'blue'){
    hotelIco = hotelIcon;
    mapIco = locationIcon;
  }

  const tabs = [
    { name: 'Hotel Information', icon: hotelIco },
    { name: 'Map', icon: mapIco },
    { name: 'Tripadvisor', icon: tripadvisorIcon },
  ];

  const HotelInformationContent = (
    <>
      <h2 className='package-page-title'>Description</h2>

      <div className='package-page-hotel-detail__copy'>{accommodation.detail}</div>

      <div className='package-page-hotel-divider' />

      {/* <div>
        <h2>Facilities</h2>

        <ul className='package-page-hotel-facilities-list'>
          <li>24-hour front desk</li>
          <li>Free Wi-Fi</li>
          <li>Swimming pool</li>
          <li>Restaurant</li>
          <li>Fitness center</li>
          <li>Spa</li>
          <li>Parking</li>
          <li>Room service</li>
          <li>Bar/lounge</li>
          <li>Business center</li>
          <li>Concierge service</li>
          <li>Laundry service</li>
          <li>Conference facilities</li>
          <li>Airport shuttle</li>
          <li>Childcare services</li>
          <li>Tennis court</li>
          <li>Golf course</li>
          <li>Currency exchange</li>
          <li>Gym</li>
          <li>Banquet hall</li>
          <li>Indoor pool</li>
          <li>Outdoor pool</li>
          <li>Jacuzzi</li>
          <li>Sauna</li>
          <li>Gift shop</li>
          <li>Beauty salon</li>
          <li>Babysitting</li>
          <li>Car rental</li>
          <li>Valet parking</li>
          <li>Convenience store</li>
        </ul>
      </div> */}
    </>
  );


  let totalPrice = 0;
  let totalPricePerPerson = 0;
  const gotRooms = [];
  if(holidayPackage != null){
    for (const r of holidayPackage.rooms) {
      if(gotRooms.indexOf(r.room_number) != -1){
        continue
      }
      totalPrice += r.price;
      totalPricePerPerson += r.price_per_person;
      gotRooms.push(r.room_number);
    }
  }


  const MapContent = (
    <Map
      hideInfo
      shouldShowInfoBox={false}
      key={accommodation.id}
      activePlace={{
        id: accommodation.id ? accommodation.id.toString() : '',
        title: accommodation.name || '',
        images: accommodation.images || [],
        position: {
          lat: parseFloat(accommodation.lat || ''),
          lng: parseFloat(accommodation.long || ''),
        },
        price: { total: totalPrice, pricePerPerson: totalPricePerPerson }
      }}
      places={[
        {
          id: accommodation.id ? accommodation.id.toString() : '',
          title: accommodation.name || '',
          images: accommodation.images || [],
          position: {
            lat: parseFloat(accommodation.lat || ''),
            lng: parseFloat(accommodation.long || ''),
          },
          price: { total: totalPrice, pricePerPerson: totalPricePerPerson }
        },
      ]}
      
      center={{
        lat: parseFloat(accommodation.lat || ''),
        lng: parseFloat(accommodation.long || ''),
      }}
      hotelName={accommodation.name || ''}
      hotelLocation={accommodation.resort.name}
      rating={accommodation.rating
      }
      // tripadvisorRating={tripadvisorRating}
      // tripadvisorReviews={tripadvisorReviews}
      price={0}
      // onMarkerClick={(place: Place) => setSelectedPlace(place)}
      onMarkerClick={() => console.log('marker clicked')}
    />
  );

  const TripadvisorReviewsContent = <TARatings tripAdvisor={accommodation.trip_advisor || ''} accommodation={accommodation}></TARatings>


  const tabsData: TabsData = {
    tabsList: tabs,
    tabsContent: [HotelInformationContent, MapContent, TripadvisorReviewsContent],
  };

  let atolIco = atolIconDefault;
  let abtaIco = abtaIconDefault;
  if(configuration.icon_set == 'blue'){
    atolIco = AtolIcon;
    abtaIco = AtbaIcon;
  }

  let selecting = null;
  if(offerSelecting){
    selecting = <div className='package-search-loader-overlay'>
        <div className='package-search-loader-modal'>
          <p className='package-search-loader-modal__title'>Just a moment...</p>

          <div className='package-search-loader-modal__spinner'>
            <LoaderSpinner />
          </div>

          <p>Loading, this won't take long.</p>

          <div className='package-search-loader-modal__logos'>
            <img width={150} src={abtaIco} />
            <img width={50} src={atolIco} />
          </div>
        </div>
      </div>

  }

  let summarySec = null;
  if(holidayPackage != null){
    summarySec = <aside className='package-page-aside'>
    <PackageHolidaySummary
      offer={holidayPackage}
      selectedSearchParams={searchParams}
      isPackage
      headerTitle='Holiday Summary'
      // Need replacing with the correct data
      onHolidaySelect={() => selectOffer()}
      isFetchingData={false}
      pricePlan={paymentPlan}
    />
  </aside>
  }
  else{
    if(!isDumb){
      summarySec = <aside className='package-page-aside'>
      <div className='holiday-summary-wrapper'>
     
             <div style={{backgroundColor: configuration.container_colour}} className='holiday-summary--header'>
               <span className='holiday-summary--header__span'>No availability</span>
             </div>
             <div style={{borderColor: configuration.container_colour}} className='holiday-summary-body'>
               Sorry, this offer is no longer available. Please try selecting a different departure date or duration.
             </div>
         </div>
     
       </aside>
    }
  }

  return (
    <BaseLayout userToken={userToken} userConfiguration={configuration} showPackageRefine={true}>



      <div className='package-page'>

          {selecting}

        <div className='package-page-wrapper'>



              <div>
                <header className='hwt-header package-page-header'>



                  <div>
                    <h1 className='package-page-title'>{accommodation.name}</h1>

                    {accommodation?.resort && (
                      <p style={{color: configuration.text_colour_secondary}} className='package-page-location location'>
                        {accommodation.resort.name}
                      </p>
                    )}
                  </div>

                  <div className='name-section-wrapper'>
               
                      <Ratings
                        tripAdvisor={accommodation.trip_advisor}
                        hotelRating={Number(accommodation.rating)}
                      />
                  
                  </div>
                </header>

      
                <ImageSlider
                  images={accommodation.images || []}
                  height={600}
                  imgW={-1}
                  imgH={-1}
                  gap='0rem'
                />
           

                <div style={{borderColor: configuration.border_colour_2}} className='package-page-hotel-detail'>
                  <SimpleTabs
                    activeTabNumber={activeTabNumber}
                    tabsData={tabsData}
                    onTabChange={setActiveTab}
                  />
                </div>
              </div>

              {summarySec}
         
        </div>
      </div>
    </BaseLayout>
  );
}
